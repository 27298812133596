var baseconfig;
var session;
var sessionStatus;
var userManager;
var groupInfo;
var jstoastr;
var getDataLoop;
var isCallbackCalling;
var old_timestamp;
var systemSettingMarker;
var baseLatitude;
var baseLongitude;
var baseProgressInterval;
var baseCallbackData;
var baseEmergencyList = [];
var rechordObj;
var toastOption = {
    has_icon:true,
    has_close_btn:true,
    stack: true,
    fullscreen:true,
    timeout:3000,
    sticky:false,
    has_progress:true,
    rtl:false,
    position_class:"toast-bottom-right",
}

var baseUnits = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

var toastBottomRightOption = Object.assign({},toastOption);
toastBottomRightOption.position_class = "toast-bottom-right";

var toastTopCenterOption  = Object.assign({},toastOption);
toastTopCenterOption.position_class =  "toast-top-center";

var toastSOSBottomRightOption = Object.assign({},{
    has_icon:true,
    has_close_btn:true,
    stack: true,
    fullscreen:true,
    timeout:(1000*60)*60*60,
    sticky:false,
    has_progress:true,
    rtl:false,
    position_class:"toast-bottom-right",
});

function sessionCallback(sender, event, json) {
    
    sessionStatus = (json.code == jSW.RcCode.RC_CODE_S_OK);

    if(sessionStatus){

        callGroupList();
    }
}

function notificationCallback(sender, event, json) {

    if(json.msg == "notify_pu_onoffline" ){

        if(session._arr_pu_online.length > 0)
        {
            for(var i=0;i<session._arr_pu_online.length;i++){

                userManager .swGetUAInfoByPuid({
                    targetId:session._arr_pu_online[i]._id_pu,
                    tag:null,
                    callback: function (options, res,result) 
                        {
                            var index = session._arr_pu_online.map(function(e) { return e._id_pu; }).indexOf(result[0].szdevid);

                            session._arr_pu_online[index]._userid = result[0].szuserid;
                            session._arr_pu_online[index]._username = result[0].szusername;
           
                            if(index==session._arr_pu_online.length-1){
                                
                                CreateActiveDevicesList();
                                createActiveLiveList();
                                CreateActiveCommunicationList();
                                refreshDeviceList();
                                checkActiveSOS();
                            }
                        }
                    });
            }
        }
        else{

            CreateActiveDevicesList();
            createActiveLiveList();
            CreateActiveCommunicationList();
            refreshDeviceList();
            checkActiveSOS();
        }
    }else if(json.msg ==  "notify_event_source" && json.content.iEventType == 4099){ /// 4099 press sos, 4115 low bettery

        var sosInfo = session._arr_pu_online.filter(q => q._id_pu == json.content.szID)[0];

        if(sosInfo != null){

            $("#audioPoliceSiren")[0].currentTime = 0;
    
            $("#audioPoliceSiren")[0].play();

            sosInfo.name = sosInfo._userid;
   
            groupInfo.forEach((group) => {
                if (group._userlist) {
                    group._userlist.forEach((userGroup) => {
                        if(userGroup._user.id == sosInfo._userid)  sosInfo.name  = userGroup._user.name;
                    });
                }
            });

            if($('.sos.toast-close.'+sosInfo._userid).length == 0){
                $.Toast('SOS ALERT',  {id:sosInfo._userid,value:sosInfo.name +"</br>("+ sosInfo._userid+")"}, "sos" , toastSOSBottomRightOption,json.content.szID);
            }

            // var new_timestamp = new Date().valueOf();

            // if(old_timestamp == null || old_timestamp+2000 < new_timestamp){

            //     old_timestamp = new Date().valueOf();
            
            // }
        }
        
    }
    
}

function baseNotificationOnOfflineCallback(event,id){

    if(event == 'login'){

        $.Toast("", id + " is Online!", "success", toastBottomRightOption);
    }else if(event == 'logout'){
        $.each($('.sos.toast-close.'+id), function (key, val) {
            val.click();
        });
        $.Toast("",id + " is Offline!", "error", toastBottomRightOption);
    }
}

function callGroupList() {

    if(!userManager){

        userManager = session.swGetUserManager();
    }
    
    userManager.swInit({
        callback: function (sender, response, data) {
            userManager.swGetGroupList({
                callback: function (sender, event, data) {

                    groupInfo = data;

                    groupInfo.forEach((group)=>{
                        group._userlist.forEach((user)=>{
                            user.swGetUserInfo({callback: function (sender, event, data) { 

                            }});
                        });
                    });
                },
                ondatachanged: function (sender, response, data) {},
                tag: null
            });
        },
        ondatachanged: function (sender, response, data) {},
        tag: null
    });
}

function refreshDeviceList(){

    if($(".main.device-management #refreshDeviceManamentList").html() != undefined){

        $("#refreshDeviceManamentList").click();
    }
    if($(".main.device-file-list #closeDeviceFileList").html() != undefined){

        $("#closeDeviceFileList").click();
    };
}

function callUserList() {    //GetUser By ID

    userGroupInfo = [];
    userManager = session.swGetUserManager();
    var result = "";
    for (var iIndex = 0; iIndex < groupInfo.length; iIndex++) {
        group = groupInfo[iIndex]._group;
        var group = userManager.swGetGroupById(group.id);
        group.swGetUserList({
            callback: function (sender, event, data) {

                groupInfo.forEach((group)=>{

                    group._group.parent =  data.filter(q=>q._user.groupid == group._group.id);
                });
            },
            ondatachanged: function (sender, response, data) {},
            tag: null
        });
    }

}

function isWaiting(data,message = "Loading..."){

    $("#div-waiting .waiting-message  img.loading-img").show();
    $("#div-waiting .waiting-message  #waiting-timer").hide();


    if(data)
        document.getElementById("div-waiting").style.display = "block";
    else
        document.getElementById("div-waiting").style.display = "none";

        $("#div-waiting .waiting-message .loading-text").html(message);
}

function isTimerWaiting(data,message = "Loading...",timer=30){

    $("#div-waiting .waiting-message  img.loading-img").hide();
    $("#div-waiting .waiting-message  #waiting-timer").show();

    if(data){

        document.getElementById("div-waiting").style.display = "block";
        startTimer(timer);
    }
    else
    {

        document.getElementById("div-waiting").style.display = "none";
        
        if (baseTimerInterval != null) {
            clearInterval(baseTimerInterval);
        }
    }

     $("#div-waiting .waiting-message .loading-text").html(message);
}

function downloadImage(data, filename = 'untitled.jpg') {
    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
}

function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

function pad2(n) { return n < 10 ? '0' + n : n }

function getDateExtendtion(){
    
    var date = new Date();
    return date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2( date.getDate())+ "-" + pad2( date.getHours() ) + pad2( date.getMinutes() )+ pad2( date.getSeconds() );
}


function getDateNow(){
    
    var date = new Date();
    return date.getFullYear().toString() +"-"+ pad2(date.getMonth() + 1)  +"-"+ pad2( date.getDate())+ " " + pad2( date.getHours() )  +":"+ pad2( date.getMinutes() )  +":"+ pad2( date.getSeconds() );
}

function downloadBlobVideo(blob_url, filename = 'untitled.mp4')
{
	var fileName = filename;
	var a = document.createElement('a');
	a.href        = blob_url;
	a.download    = fileName;
    document.body.appendChild(a);
    a.click();
}

function randomString(length = 8) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }


 
function onCloseModal(obj){

    $(obj).closest(".w3-modal").hide();
}

//// TImer ///////
var FULL_DASH_ARRAY = 283;
var WARNING_THRESHOLD = 10;
var ALERT_THRESHOLD = 5;

var Base_COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};

var BASE_TIME_LIMIT;
var baseTimePassed;
var baseTimeLeft;
var baseTimerInterval;
var remainingPathColor;

function onTimesUp() {
    clearInterval(baseTimerInterval);
}

function startTimer(timer = 30) {
    BASE_TIME_LIMIT = timer;
    baseTimePassed = 0;
    baseTimeLeft = BASE_TIME_LIMIT;

    if (baseTimerInterval != null) {
        clearInterval(baseTimerInterval);
    }

    baseTimerInterval = null;


    remainingPathColor = Base_COLOR_CODES.info.color;

    document.getElementById("waiting-timer").innerHTML = `
  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
          id="base-timer-path-remaining"
          stroke-dasharray="283"
          class="base-timer__path-remaining ${remainingPathColor}"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span id="base-timer-label" class="base-timer__label">${formatTime(
        baseTimeLeft
    )}</span>
  </div>
  `;

    baseTimerInterval = setInterval(() => {
        baseTimePassed = baseTimePassed += 1;
        baseTimeLeft = BASE_TIME_LIMIT - baseTimePassed;
        document.getElementById("base-timer-label").innerHTML = formatTime(
            baseTimeLeft
        );
        setCircleDasharray();
        setRemainingPathColor(baseTimeLeft);

        if (baseTimeLeft === 0) {
            onTimesUp();
        }
    }, 1000);
}

function formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
}

function setRemainingPathColor(baseTimeLeft) {
    const { alert, warning, info } = Base_COLOR_CODES;
    if (baseTimeLeft <= alert.threshold) {
        document
            .getElementById("base-timer-path-remaining")
            .classList.remove(warning.color);
        document
            .getElementById("base-timer-path-remaining")
            .classList.add(alert.color);
    } else if (baseTimeLeft <= warning.threshold) {
        document
            .getElementById("base-timer-path-remaining")
            .classList.remove(info.color);
        document
            .getElementById("base-timer-path-remaining")
            .classList.add(warning.color);
    }
}

function calculateTimeFraction() {
    const rawTimeFraction = baseTimeLeft / BASE_TIME_LIMIT;
    return rawTimeFraction - (1 / BASE_TIME_LIMIT) * (1 - rawTimeFraction);
}

function setCircleDasharray() {
    const circleDasharray = `${(
        calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document
        .getElementById("base-timer-path-remaining")
        .setAttribute("stroke-dasharray", circleDasharray);
}


///// End timer /////

function baseImnotification(obj){

    if(obj && [1,2,4,5,6,8].includes(obj.szmsg[0].iType)){

        var activeDevice = [];
        var id = obj.szSourceId.replace("UA_","PU_").toUpperCase();
        var target = obj.szTargetId.replace("UA_","PU_").toUpperCase();
        var message = [];
        var msg = [];
        var idUserSender = "";
        var nameUserSender = "";
        var idUserTraget= "";
        var nameUserTraget= "";

        if(id == "CU_SW"){

            idUserSender = session._userManager._currentUser._user.id;
            nameUserSender = bastGetUserNamefromID(idUserSender);
        }   
        else if(id.indexOf("PU_")>=0){

            idUserSender = session._arr_pu_online.filter(l=>l._id_pu == id)[0]._userid;
            nameUserSender = bastGetUserNamefromID(idUserSender);
        }
            

        if(target == "CU_SW"){

            idUserTraget = session._userManager._currentUser._user.id;
            nameUserTraget = bastGetUserNamefromID(idUserTraget);
        }
        else  if(target.indexOf("PU_")>=0){

            idUserTraget =  session._arr_pu_online.filter(l=>l._id_pu == target)[0]._userid;
            nameUserTraget = bastGetUserNamefromID(idUserTraget);
        }else{

            idUserTraget = target;
        }
            
        session._arr_pu_online.forEach(l=>{

            activeDevice.push(l._id_pu);
        });

        if(activeDevice.includes(id)){

            obj.szmsg.forEach(l=>{
    
                var row = {
                    type:l.iType,
                    data:""
                };
    
                switch(l.iType){
                    case 1:
    
                        row.data = l.szTextMsg;
                    break;
                    case 2:
                    
                        row.data = l.szTextMsg.url;
                    break;
                    case 4:
                        if(typeof(l.stFile.szFile) != undefined){

                            row.data = l.stFile.szFile;
                        }else{
                            
                            row.data = l.stFile;
                        }
                        
                    break;
                    case 5:

                        if(typeof(l.stFile.szFile) != undefined){

                            row.data = l.stFile.szFile;
                        }else{
                            
                            row.data = l.stFile;
                        }
                    break;
                    case 6:
                    case 8:

                        if(typeof(l.stFile.szFile) != undefined){

                            row.data = l.stFile.szFile;
                        }else{
                            
                            row.data = l.stFile;
                        }
                    break;
                }
    
                msg.push(row);
            });
    
            if(!localStorage.getItem("_localMessage")){
    
                localStorage.setItem("_localMessage","");
            };
    
            if(localStorage.getItem("_localMessage") != "") message =  JSON.parse(localStorage.getItem("_localMessage"));

            var newMsg = {
                id: idUserSender,
                name:nameUserSender,
                traget:idUserTraget,
                tragetName:nameUserTraget,
                create: getDateNow(),
                value: msg
            };

            message.forEach(function(l,index,object){

                var today = new Date();

                if(today.setDate(today.getDate() - 3) > new Date(l.create)){
                    
                    object.splice(index, 1);
                }
            });

            message.push(newMsg);
    
            localStorage.setItem("_localMessage",JSON.stringify(message));
    
            notificationCommunication(newMsg);

        }
    }
}

function baseStartConference(obj){
    
    startConference(obj);
}

function baseEndConference(obj){


    endConference(obj);
}

function bastGetUserNamefromID(id) {
  
    var name = "";
  
    groupInfo.forEach(n => {
  
      if (n._userlist) {
  
        var userInfo = n._userlist.filter(q=>q._userinfo && q._userinfo.id == id);
  
        if(userInfo!=null && userInfo.length > 0){
  
          name = userInfo[userInfo.length-1]._user.name;
        } 
  
      }
    });
  
    return name;
  }

  function baseGetTimeBegin(){

    return Math.floor(new Date("2019-01-01") / 1000);
  }
  
  function baseGetTimEnd(){

    return   Math.floor(new Date() / 1000);
  }

  function baseGetFilenameFromURL(url){

        return url.split('/').pop().split('#')[0].split('?')[0];
    }

    function baseGetFileExtension(fileName){

        return fileName.split('.').pop();
    }

    function baseGetNiceBytes(x,digit=2){

        var l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024) {
            n = n / 1024;
            l++;
        }
        if(digit == undefined){
            return (n.toFixed(3) + ' ' + baseUnits[l]);
        }else{
            return (n.toFixed(digit) + ' ' + baseUnits[l]);
        }
    }

    function baseGetfileType(fileName){

        var fileExt = baseGetFileExtension(fileName).toUpperCase();

        switch(fileExt){
            case "JPG":
            case "JPEG":
            case "BMP":
            case "PNG":
                return "Pictures"
            case "MKV":
            case "MP4":
            case "AVI":
                return "Video"
            case "AAC":
            case "MP3":
                return "Audio"   
            default: return "File"
        }

    }

    function baseProgressBarStart(byte = 100000){

        $('#waiting-upload-div-progress')
        .attr('aria-valuenow', 0)
        .css('width', 0+'%');

        $('#waiting-upload-div-spanprogress').html(0 + ' %');
        
        var width = 1;
        baseProgressInterval = setInterval(frame, 10);
        function frame() {
          var running = (width/byte)*100;
          if (running.toFixed(0) >= 100) {
            clearInterval(baseProgressInterval);
          } else {
            width+=1000;
            $('#waiting-upload-div-progress')
            .attr('aria-valuenow', running.toFixed(0))
            .css('width', running.toFixed(0)+'%');

            $('#waiting-upload-div-spanprogress').html(running.toFixed(0) + ' %');

          }
        }

        $('#progress-overlay').show();
    }

    function baseProgressBarEnd(){

        if(baseProgressInterval) clearInterval(baseProgressInterval);

        $('#waiting-upload-div-progress')
        .attr('aria-valuenow', 100)
        .css('width', 100+'%');

        $('#waiting-upload-div-spanprogress').html(100 + ' %');

        setTimeout(() => {
            
            $('#progress-overlay').hide();
        }, 1500);
    }

    var googleMapDashboard;
    
    function initMapDashboard() {

        var mapProp= {
          center:new google.maps.LatLng( baseLatitude, baseLongitude),
          zoom: baseconfig.defaultlocation.zoom,
          streetViewControl: false,
          mapTypeControl: false
        };
        googleMapDashboard = new google.maps.Map(document.getElementById("googleDashboard"),mapProp);
    }

    String.prototype.toHHMMSS = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }

function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function getUrlVars() {
    if ((i = window.location.href.indexOf('?')) >= 0) {
      const queryString = window.location.href.substring(i+1);
      if (queryString) {
        return _mapUrlParams(queryString);
      } 
    }
    
    return {};
}

function _mapUrlParams(queryString) {
return queryString    
    .split('&') 
    .map(function(keyValueString) { return keyValueString.split('=') })
    .reduce(function(urlParams, [key, value]) {
    if (Number.isInteger(parseInt(value)) && parseInt(value) == value) {
        urlParams[key] = parseInt(value);
    } else {
        urlParams[key] = decodeURI(value);
    }
    return urlParams;
    }, {});
}