var communicationList = [];
var communicationOldTimeStamp = [];
var isShowMarker = false;
var audioStream;
var keysdown;
var comTimerInterval;
var comTimePassed;
var comTimeLeft;

function initLiveCommunication() {

	communicationList = [];
  communicationOldTimeStamp = [];
  isShowMarker = false;

	var mapProp = {
	  center: new google.maps.LatLng(baseLatitude, baseLongitude),
	  zoom: baseconfig.defaultlocation.zoom,
	  streetViewControl: false,
	  mapTypeControl: true
	};
  googleMap = new google.maps.Map(document.getElementById("googleMap"), mapProp);

  var iconHtml= "<div class= 'header'>";
  for(var i=1;i<=104;i++){
    
    var strId = "0000"+i.toString();
    strId = strId.substring(strId.length - 3);
    iconHtml += "<img src='assets/js/baseVideo/jswlib/res/emotion/f"+strId+".gif' onclick='onFaceSelectClick(\""+strId+"\")'>";

    if(i%13 == 0){

      iconHtml+= "</div><div class= 'header'>";
    }
  }
  iconHtml+= "</div>";

  $(".main.communication #frame .content .message-input .iconDialog .divWraper").html(iconHtml);

    
  $(".main.communication #frame #emojiText").on('keydown', function(e) {

    if (e.which == 13) {
      newMessage();
      return false;
    }

    if([8,37,38,39,30,46].includes(e.which)) return true; 

    var obj =$('.main.communication #frame #emojiText');

    return ($(obj).html().length - ($(obj).find("img").length * 59) + $(obj).find("img").length) < 100;
  });

  keysdown = false;

  $(document).on('keyup', function(e) {

    if (e.which == 32) {

      if($(".main.communication #communicationChat").html() != undefined 
      && $(".main.communication #frame .social-media .fa-satellite-dish").hasClass("color-green")){
        $(".main.communication #frame .content .message-input .message-voice div.message-voice-image").removeClass("active");
        
        onAdminHangDownPuttoTalk();
        keysdown = false;
        return false;
      }
    }
  }).on('keydown', function(e) {

    if (e.which == 32 && !keysdown) {
 
      if($(".main.communication #communicationChat").html() != undefined 
      && $(".main.communication #frame .social-media .fa-satellite-dish").hasClass("color-green")){
        $(".main.communication #frame .content .message-input .message-voice div.message-voice-image").removeClass("active").addClass("active");

        onAdminHangUpPuttoTalk();
        keysdown = true;
        return false;
      }
    }
  });
}

function CreateActiveCommunicationList(){

    if($(".main.communication #communicationChat").html() != undefined){
        var isExistId = [];

        var txtHtml = "<ul><li><span class='liGroup'>Groups ("+ session._confManager._conf_list_names.length +")</span><i class='liCollapse fas fa-chevron-up' onclick='onContractCollapse(this)'></i><ul>";
            
        session._confManager._conf_list_names.forEach(l=>{

          var currentObj = session._confManager._conf_list[l];
    
          if(!isExistId.includes(currentObj._conf_base_info.id)) {

            isExistId.push(currentObj._conf_base_info.id);

            var groupUserExist = [];

            currentObj._conf_particulars.forEach(loop=>{

              if(groupUserExist.filter(q=>q.name == loop.name).length == 0) {

                groupUserExist.push(loop);
              }
            });

            if(communicationList.filter(q=>q.type == "group" && q.groupInfo.id == currentObj._conf_base_info.id).length == 0){

              communicationList.push({
                groupInfo:currentObj._conf_base_info,
                groupUsers: groupUserExist,
                imageURL:"./assets/images/icon/user-group.png",
                type:"group",
                activeChat: false,
                activeMic: false,
                isStartMeeting: false
              });
            }else{

              var group = communicationList.filter(q=>q.type == "group" && q.groupInfo.id == currentObj._conf_base_info.id)[0];
              group.groupInfo = currentObj._conf_base_info;
              group.groupUsers = groupUserExist;
            }
            

            txtHtml += "<li class='contact contactGroup contact"+currentObj._conf_base_info.id+"' ondblclick='onOpenGroupMessagePanel(this,\""+currentObj._conf_base_info.id+"\")'><div class='wrap d-flex align-items-center'>";
            txtHtml += "<img src='./assets/images/icon/user-group.png' alt='' /><div class='meta'>";
            txtHtml += "<p class='name'>"+currentObj._conf_base_info.name +" ("+groupUserExist.length+")</p></div></div></li>";
          }
          
        });

        txtHtml += "</ul></li>";

        var counts = [];

        session._arr_pu_online.forEach(function(l) { 

          var currentId = l._userid ;

          if(!counts.includes(currentId)) {

            counts.push(currentId);
          };
        });

       txtHtml += "<li><span class='liGroup'>Online Users ("+counts.length+")</span><i class='liCollapse fas fa-chevron-up' onclick='onContractCollapse(this)'></i><ul>";

        if(session._arr_pu_online.length > 0){

            session._arr_pu_online.forEach(l => {

                var currentId = l._userid ;

                if(!isExistId.includes(currentId)) {

                  isExistId.push(currentId);

                  if(communicationList.filter(q=>q.type == "user" && q.deviceId == l._id_pu).length == 0){
  
                    communicationList.push({
                      deviceId:l._id_pu,
                      userId:currentId,
                      userName: l._username,
                      type:"user",
                      activeChat: false,
                      activeMic:false,
                      isGetGps:false
                    });
  
                  }else{
                    var device =  communicationList.filter(q=>q.type == "user" && q.deviceId == l._id_pu)[0];
                    device.id = l._id_pu;
                    device.userId = currentId;
                    device.userName = l._username;
                  }
                  
                  var device =  communicationList.filter(q=>q.type == "user" && q.deviceId == l._id_pu)[0];
                  
                  var pinImg = new Image();
                  var pinImageURL = baseconfig.imageURL+ '/profile/'+currentId+'-pin.png?rnd='+Math.random().toString(36).substring(7);
                  pinImg.src = pinImageURL;
                  device.pinImageURL = pinImageURL;
                  pinImg.onerror = function(error,id = currentId){
    
                    communicationList.filter(q=>q.type == "user" && q.userId == id)[0].pinImageURL = "assets/images/no-photo-pin.png";
                  }
  
                  pinImg.onload = function(){ };
  
                  var img = new Image();
                  var imageURL = baseconfig.imageURL+ '/profile/'+currentId+'.png?rnd='+Math.random().toString(36).substring(7);
                  img.src = imageURL;
                  device.imageURL = imageURL;
                  img.onerror = function(error,id = currentId){
     
                    communicationList.filter(q=>q.type == "user" && q.userId == id)[0].imageURL = "assets/images/no-photo.png";
                    communicationList.filter(q=>q.type == "user" && q.userId == id)[0].pinImageURL = "assets/images/no-photo-pin.png";
                  }
  
                  img.onload = function(){ };

                  txtHtml +=  "<li class='contact contact"+currentId+"' ondblclick='onOpenUserMessagePanel(this,\""+currentId+"\")'><div class='wrap d-flex align-items-center'><span class='contact-status online'></span>";
                  txtHtml +=  "<img src='"+ imageURL +"' onerror=\"this.src='assets/images/no-photo.png'\" /><div class='meta'>";
                  txtHtml +=  "<p class='name'>"+  communicationList.filter(q=>q.type == "user" && q.deviceId == l._id_pu)[0].userName  +"</p></div></div></li>";

                }
            });
        }

        txtHtml +=  "</ul></li></ul>";

        $(".main.communication #communicationChat #contacts").html(txtHtml);

        $(".main.communication #communicationChat #search input").val('');

        removeOfflineCommunication(isExistId);

        eva.replace();

    }
}


function closeCommunicationPage() {
  
  isShowMarker = false;

  onClearMeeting();

  communicationList.filter(q=>q.type == "user").forEach(l=>{

    l.isGetGps = false;
  });

  removeOfflineCommunication([]);
}

function removeOfflineCommunication(isExistId){

  if(communicationList.filter(q=>q.activeChat).length==0) activeMessagePanel(false);

  communicationList.forEach(function(l,index,object){

    var id = l.userId ?? l.groupInfo.id;
    
    if(l.activeChat == true) {

      $(".main.communication #communicationChat #contacts .contact"+id).addClass("active");
    }

    if(l.type == "user"){

      if(!isExistId.includes(l.userId)){

        l.isGetGps = false;
      }

      if(!l.isGetGps){

        l.latitude = null;
        l.longitude = null;
        if (l.gps) {
          l.gps.swClose();
          l.gps._callback = null;
          if (l.marker) {
            l.marker.setMap(null);
            l.marker = null;
          }
        }
        l.gps = null;
      }

    }

    if(!isExistId.includes(l.userId) && !isExistId.includes(l.groupInfo?.id) ) {
  
      if(l.activeChat){

        l.activeChat = false;

        activeMessagePanel(false);
      }


      object.splice(index, 1);
    }

  });

  if(isShowMarker) {

    isWaiting(true);
    
    setTimeout(() => {
      
      isWaiting(false);
      onCommunicationShowMap();
    }, 3000);
  } 
}

function onCommunicationClickExpand() {
    $("#profile").toggleClass("expanded");
      $("#contacts").toggleClass("expanded");
}

function onCommunicationClickStatusLi() {
  $("#profile-img").removeClass();
	$("#status-online").removeClass("active");
	$("#status-away").removeClass("active");
	$("#status-busy").removeClass("active");
	$("#status-offline").removeClass("active");
	$(this).addClass("active");
	
	if($("#status-online").hasClass("active")) {
		$("#profile-img").addClass("online");
	} else if ($("#status-away").hasClass("active")) {
		$("#profile-img").addClass("away");
	} else if ($("#status-busy").hasClass("active")) {
		$("#profile-img").addClass("busy");
	} else if ($("#status-offline").hasClass("active")) {
		$("#profile-img").addClass("offline");
	} else {
		$("#profile-img").removeClass();
	};
	
	$("#status-options").removeClass("active");
}

function onContractCollapse(obj){

	if($(obj).hasClass("fa-chevron-up")){

		$(obj).closest('li').find("ul:first").hide();
		$(obj).removeClass("fa-chevron-up").addClass("fa-chevron-down");
	}else{

		$(obj).closest('li').find("ul:first").show();
		$(obj).removeClass("fa-chevron-down").addClass("fa-chevron-up");
	}
}

function onCommunicationClickTalk(){
  var obj = $(".main.communication #communicationChat #frame .content .message-input .btMicrophone");

	var talkPanel = $(".main.communication #communicationChat #frame .content .message-input div.message-voice");

	if(talkPanel.hasClass("hidden")){

		talkPanel.removeClass("hidden");
		talkPanel.removeClass("show").addClass("show");
		obj.removeClass("red").addClass("red");
		obj.closest('.content').find("div.messages").addClass("talk");
	}else{

		talkPanel.removeClass("hidden").addClass("hidden");
		talkPanel.removeClass("show");
		obj.removeClass("red");
		obj.closest('.content').find("div.messages").removeClass("talk");
	}

	scoreMessageToBottom();
}

function onOnpenMicrophone(isStart){

  var btMicrophone = $(".main.communication #communicationChat #frame .content .message-input .btMicrophone");
  var btMessage =   $(".main.communication #communicationChat #frame .content .message-input .btMessage");
  var textWrapper =   $(".main.communication #communicationChat #frame .content .message-input #text_wrapper #emojiText");
  var talkPanel = $(".main.communication #communicationChat #frame .content .message-input div.message-voice");
  
  if(isStart){

    btMicrophone.removeClass("objInActive");
    btMessage.removeClass("objInActive").addClass("objInActive");
    textWrapper.html("");
    textWrapper.removeClass("objInActive").addClass("objInActive");
    talkPanel.removeClass("hidden");
		talkPanel.removeClass("show").addClass("show");
		btMicrophone.removeClass("red").addClass("red");
		btMicrophone.closest('.content').find("div.messages").addClass("talk");
  }else{

    btMicrophone.removeClass("objInActive").addClass("objInActive");
    btMessage.removeClass("objInActive");
    textWrapper.removeClass("objInActive");
    talkPanel.removeClass("hidden").addClass("hidden");
		talkPanel.removeClass("show");
		btMicrophone.removeClass("red");
		btMicrophone.closest('.content').find("div.messages").removeClass("talk");
  }
}

function onSearchContact(obj){

    $(obj).closest("#sidepanel").find("li.contact").each(function(index,value){

        $(value).removeClass("hidden");

        if($(value).find("p:first").html().toUpperCase().indexOf($(obj).val().toUpperCase()) < 0){
          
          $(value).addClass("hidden");;
        }

    });
}

function openDialogCreateMeeting(){

  document.getElementById('createMeetingModal').style.display='block';

  $("#createMeetingModal #groupName").removeClass("is-invalid");
  $("#createMeetingModal #groupName").val('');
  $("#createMeetingModal #rioHost").prop("checked", true);
  $("#createMeetingModal #rioInvited").prop("checked", true);
  $("#createMeetingModal #rioAuto").prop("checked", true);
  $("#createMeetingModal #rioInitByAdmin").prop("checked", true);
}

function closeCreateMeeting(){

  document.getElementById('createMeetingModal').style.display='none';
}

function onCreateMeetingSubmit(obj){

  var control = $(obj).closest("form");
  
  var groupName =  $(control).find("#groupName:first").val();
  var groupType =   $(control).find("input[name='rioGroupType']:checked:first").val();
  var groupJoinMtd =   $(control).find("input[name='rioJoinMode']:checked:first").val();
  var groupdSpeakToMtd =   $(control).find("input[name='rioSpkToMtd']:checked:first").val();
  var groupStartMtd =   $(control).find("input[name='rioStartMtd']:checked:first").val();

  if(groupName.trim() == ""){
    
    $(control).find("#groupName:first").addClass("is-invalid");
  }else{

    isWaiting(true);

    session.swGetConfManager().swCreateConf({
      confbaseinfo: {
          name: groupName,
          speakmode: parseInt(groupType),
          joinmode: parseInt(groupJoinMtd),
          applyformode: parseInt(groupdSpeakToMtd),
          startmode: parseInt(groupStartMtd),
          password: ''
      },
      callback:  (sender, event, json) => {

        isWaiting(false);

        if(event.emms.code!=0){

          if(event.emms.code==20126){
            $.Toast("","Group name is already in use! Please change the group name to a different name.", "error", toastTopCenterOption);
          }else{
            $.Toast("","Unable to create communication group! An unexpected error has occurred.", "error", toastTopCenterOption);
          }
        }else{

          $.Toast("","Communication group created successfully!", "success", toastTopCenterOption);
          CreateActiveCommunicationList();
          closeCreateMeeting();
        }
      }
  });
  }
}

function removeInvalidClass(obj){

  $(obj).removeClass("is-invalid");
}

/////// MAP //////////////

function onCommunicationPinMarker(obj){

  if($(obj).hasClass('red')){

    $(obj).removeClass('red');

    $(obj).find('span:first').html("&nbsp;Show Location");

    isShowMarker = false;

    communicationList.filter(q =>q.type == 'user').forEach(function(l,index,object){

        l.latitude = null;
        l.longitude = null;
        l.isGetGps = false;
        if (l.gps) {
          l.gps.swClose();
          l.gps._callback = null;
          if (l.marker) {
            l.marker.setMap(null);
            l.marker = null;
          }
        }
        l.gps = null;
    });
  }
  else{

    $(obj).removeClass('red').addClass('red');

    $(obj).find('span:first').html("&nbsp;Hide Location");

    isShowMarker = true;

    onCommunicationShowMap();
  }
}

function onCommunicationShowMap() {

    var devices = communicationList.filter(q =>q.type == 'user');

    devices.forEach(device => {
      
      device.latitude = null;

      device.longitude = null;

      if(!device.isGetGps){

        device.gps = session.swGetPuChanel(device.deviceId, 65536);

        if (!device.gps) {
          
          device.isGetGps = false;
          $.Toast("", "Unable to locate ["+ device.userId +"] device!", "error", toastTopCenterOption);

          return;
        }

        isWaiting(true);

        device.gps.swOpen({
          repeat: -1,
          interval: 5000,
          callback: function (options, response, data = device) {

            if(response.gps){
              
              isWaiting(false);

              if (communicationOldTimeStamp.filter(q => q.userId == device.userId).length == 0) {
  
                communicationOldTimeStamp.push({
                  userId: device.userId,
                  old_timestamp: null
                });
              }
  
              var new_timestamp = new Date().valueOf();
              
              var timeStamp = communicationOldTimeStamp.filter(q => q.userId == device.userId)[0];
  
              if (timeStamp.old_timestamp == null || timeStamp.old_timestamp + 1000 < new_timestamp) {
  
                timeStamp.old_timestamp = new Date().valueOf();
                
                if (!data || !response || !response.gps.lat || !response.gps.long) {
  
                  device.gps.swClose();
                  device.gps._callback = null;
                  device.gps = null;
                  data.isGetGps = false;
                  $.Toast("", "Unable to locate [" + device.userId + "] device!", "error", toastTopCenterOption);
                } else {
  
                  data.latitude = response.gps.lat / 10000000;
                  data.longitude = response.gps.long / 10000000;
                  data.isGetGps = true;
                  communicationPinDeviceToMap();
                }
              }
            }
          }
        });
      }
    });
}

function communicationPinDeviceToMap() {

  var devices = communicationList.filter(q => q.type == 'user' && q.gps != null);

  if ($(".main.communication").html() != undefined && devices.length > 0) {

    devices.forEach(l => {

      if (l.latitude == null || l.longitude == null) {

        if (l.marker) {
          
          l.marker.setMap(null);
          l.marker = null;
        }
      }
      else {

        if (l.marker && l.marker != null) {
          l.marker.setPosition(new google.maps.LatLng(l.latitude, l.longitude));
        } else {

          var icon = {
            url: l.pinImageURL, // url
            scaledSize: new google.maps.Size(64, 64), // scaled size
            anchor: new google.maps.Point(32, 32) // anchor
          };

          l.marker = new google.maps.Marker({
            map: googleMap,
            position: { lat: l.latitude, lng: l.longitude },
            icon: icon,
            title: l.userName
          });

          communicationSetPosition(l.userId);

        }
      }
    });
  }
}

function communicationSetPosition(id, zoom = 0) {

  var device = communicationList.filter(q => q.userId == id)[0];

  if (device.marker && device.marker != null) {

    googleMap.setCenter(device.marker.getPosition());

    if (zoom != 0) { //0 is current zoom level

      googleMap.setZoom(zoom);
    }
  } else {
    setTimeout(() => {

      communicationSetPosition(id, zoom);
    }, 1000);
  }
}
//////// Chat ///////////////////////////

var meesageLimit = 5;//20

function notificationCommunication(data){

  if($(".main.communication #communicationChat").html() != undefined){

    var msg;

    if(data.traget.indexOf("CONF_")>=0){

      msg = communicationList.filter(l=>l.activeChat==true && l.type == "group" && l.groupInfo.id == data.traget);
    }else if(data.id != null && data.id != ""){

      msg = communicationList.filter(l=>l.activeChat==true && l.type == "user" && data.id == l.userId && (data.id == l.userId && session._userManager._currentUser._user.id == data.traget) || ( data.id == session._userManager._currentUser._user.id  && l.userId == data.traget));
    }

    if(msg.length > 0){

      var msgHtml = "";
      var msgRole = "";
      var objId = data.id;
      var objName = data.name;

      if(data.id == session._userManager._currentUser._user.id)
        msgRole = "replies";
      else
        msgRole = "sent";

      msgHtml += "<li class='"+msgRole+"'>";
      
      msgHtml += "<img class='msg-profile' src='"+ baseconfig.imageURL+ '/profile/'+objId +'.png?rnd='+Math.random().toString(36).substring(7) +"' onerror=\"this.src='assets/images/no-photo.png'\" />";
      
      if(msgRole ==  "sent")
        msgHtml += "<div class='msg-header'><span class='msg-name'>"+objName+"  </span><span class='msg-datetime'>"+data.create+"</span></div>";
      else
        msgHtml += "<div class='msg-header'><span class='msg-datetime'>"+data.create+"  </span><span class='msg-name'>"+objName+"</span></div>";

      msgHtml += "<div class='msg-body'>";

      data.value.forEach(loop=>{

        msgHtml += generateMessageBody(loop,true);
      });

      msgHtml += "</div></li>";

      $(msgHtml).appendTo($('.main.communication #communicationChat .messages ul'));

      scoreMessageToBottom();
    }
 
  }
}

function getCommunicationMessage(type,id){

  var obj = JSON.parse(localStorage.getItem("_localMessage"));
  var currentId = session._userManager._currentUser._user.id;

  var msgHtml = "<ul>";

  if(obj != null){

    if(type=='user')
      obj = obj.filter(q=> (q.traget == currentId  && q.id == id) || (q.id == currentId && q.traget == id));
    else
      obj = obj.filter(q=>  q.id == id ||  q.traget == id);

    obj.forEach(function(l,i){

      var msgRole = "";
      var objId = l.id;
      var objName = l.name;

      if(l.id == currentId) // only admin can sent on website
        msgRole = "replies";
      else
        msgRole = "sent";

        if(i < obj.length  - 10) msgRole+= " hidden"
      
      msgHtml += "<li class='"+msgRole+"'>";
      
      msgHtml += "<img class='msg-profile' src='"+ baseconfig.imageURL+ '/profile/'+objId +'.png?rnd='+Math.random().toString(36).substring(7) +"' onerror=\"this.src='assets/images/no-photo.png'\" />";
      
      if(msgRole ==  "sent")
        msgHtml += "<div class='msg-header'><span class='msg-name'>"+objName+"  </span><span class='msg-datetime'>"+l.create+"</span></div>";
      else
        msgHtml += "<div class='msg-header'><span class='msg-datetime'>"+l.create+"  </span><span class='msg-name'>"+objName+"</span></div>";

      msgHtml += "<div class='msg-body'>";

      l.value.forEach(loop=>{

        msgHtml += generateMessageBody(loop);
      });
      msgHtml += "</div></li>";
    });

  }

  msgHtml += "</ul>";
  $(".main.communication #communicationChat #frame .content .messages").html(msgHtml);

  scoreMessageToBottom();
}

function generateMessageBody(value,isNewMessage = false){

  switch(value.type){
    case 1:
      return "<span>"+value.data+"</span>"
    case 2:
      return "<img  class='msg-icon' src='"+value.data+"'/>"
    case 4:
      var filename = value.data.substring(value.data.lastIndexOf('/')+1);
      filename = filename.substring(0,filename.lastIndexOf('?'));
      if(value.name){
        filename = value.name;
      }
      return "<a class='msg-link' href='"+value.data+"' target='_blank'><i class='fas fa-file-download'></i>"+filename+"</a>";
    case 5:
      return "<img class='msg-photo' src='"+value.data+"' onclick='openModalMsg(this)'/>";
    case 6:
    case 8:
      return "<audio controls ><source src='"+ baseconfig.imageURL+ "/FTPHome"+value.data+"' type='audio/mpeg'></audio>";
    default: return "";
  }
}


function openModalMsg(obj){

  $(".main.communication #imgModal").show();
  $('.main.communication #imgModal #show-img').attr('src', $(obj).attr('src'));

}

function closeModalMsg(){

  $(".main.communication #imgModal").hide();
}


function onUploadAttachFile(obj){

  $(".main.communication #uploadAttachFile").click();
}

function onUploadFileToMessage(obj){

  if(document.getElementById('uploadAttachFile').files[0].size > 2097152){

    $.Toast("","Unable to upload file more than 5 MB! An unexpected error has occurred.", "error", toastTopCenterOption);
  }else{

    var selectObj = communicationList.filter(l=>l.activeChat==true)[0];

    if(selectObj.type == "group"){

      var type = 4;

      if(document.getElementById('uploadAttachFile').files[0].type.indexOf("image") >= 0){

        var type = 5;
      }

      var msgItem = {
        iType: type,
        data: document.getElementById('uploadAttachFile').files[0]
      };

      var confManager = this.session.swGetConfManager();

      var conf = confManager.swGetConfByConfId(selectObj.groupInfo.id);

      isWaiting(true);

      conf.swConfIMSend({
        msgitems: [msgItem],
        callback: (options,response) => {
          
          if (response.emms.code != jSW.RcCode.RC_CODE_S_OK) {

            isWaiting(false);
            $.Toast("","Unable to send message! An unexpected error has occurred.", "error", toastTopCenterOption);
          }else{

            var fr = new FileReader();
      
            fr.onloadend = function(e) {

                var newMsg = {
                  id:  session._userManager._currentUser._user.id,
                  name: bastGetUserNamefromID(session._userManager._currentUser._user.id),
                  traget: selectObj.groupInfo.id,
                  tragetName: "",
                  isRead:false,
                  create: getDateNow(),
                  value:  [{
                    type: msgItem.iType,
                    data: e.target.result,
                    name: document.getElementById('uploadAttachFile').files[0].name
                  }]
                };
                
                onAddNewMessageToSession(newMsg);

                $(obj).val('');
            };
            fr.readAsDataURL(msgItem.data);
            isWaiting(false);
          }
        },
        pcallback: () => {},
        tag: null
      });
    }
    else{

      var type = 4;

      if(document.getElementById('uploadAttachFile').files[0].type.indexOf("image") >= 0){

        var type = 5;
      }

      var msgItem = {
        id: selectObj.deviceId,
        iType: type,
        data: document.getElementById('uploadAttachFile').files[0],
        nruid: "NRU_"
      };

      isWaiting(true);
      session.swImSend({
        targetid: msgItem.id,
        msgitems: [msgItem],
        callback: (options,response) => {
          if (response.emms.code != jSW.RcCode.RC_CODE_S_OK) {
            
            isWaiting(false);
            $.Toast("","Unable to send message! An unexpected error has occurred.", "error", toastTopCenterOption);
          }else{

            var fr = new FileReader();
      
            fr.onloadend = function(e) {

                var newMsg = {
                  id:  session._userManager._currentUser._user.id,
                  name: bastGetUserNamefromID(session._userManager._currentUser._user.id),
                  traget: selectObj.userId,
                  tragetName: bastGetUserNamefromID(selectObj.userId),
                  isRead:false,
                  create: getDateNow(),
                  value:  [{
                    type: msgItem.iType,
                    data: e.target.result,
                    name: document.getElementById('uploadAttachFile').files[0].name
                  }]
                };

                onAddNewMessageToSession(newMsg);

                $(obj).val('');
            };
            fr.readAsDataURL(msgItem.data);
            isWaiting(false);
          }

        },
        pcallback: () => {},
        tag: null
    })
    }

  }

}

function onAddNewMessageToSession(newMsg){

  var message = [];

  if(!localStorage.getItem("_localMessage")){
    
    localStorage.setItem("_localMessage","");
  };

  if(localStorage.getItem("_localMessage") != "") message =  JSON.parse(localStorage.getItem("_localMessage"));

  
  message.forEach(function(l,index,object){

      var today = new Date();

      if(today.setDate(today.getDate() - 3) > new Date(l.create)){
          
          object.splice(index, 1);
      }
  });

  message.push(newMsg);

  localStorage.setItem("_localMessage",JSON.stringify(message));

  notificationCommunication(newMsg);
  
}

function onShowIconDialog (obj){

  if($(obj).hasClass("red")){

    $(obj).removeClass("red");
    $(obj).closest(".message-input").find(".iconDialog:first").removeClass("hidden").addClass("hidden");
  }else{

    $(obj).removeClass("red").addClass("red");
    $(obj).closest(".message-input").find(".iconDialog:first").removeClass("hidden");
  }
}

function onFaceSelectClick(value){

  var obj = $('.main.communication #frame #emojiText');

  if(($(obj).html().length - ($(obj).find("img").length * 59) + $(obj).find("img").length) < 100){

    $('.main.communication #frame #emojiText').append("<img src='assets/js/baseVideo/jswlib/res/emotion/f"+value+".gif'>");
  };
}

function newMessage() {

  if($.trim($(".main.communication #frame #emojiText").html()) != ""){

    
  var items = [];
  var objs = $(".main.communication #frame #emojiText").html().split('<img src="assets/js/baseVideo/jswlib/res/emotion/').join("$#@!").split('.gif">').join("$#@!").split("$#@!").filter(l=>l!="");

  var selectObj = communicationList.filter(l=>l.activeChat==true)[0];

  if(selectObj.type == "group"){

    objs.forEach(obj=>{

      if(obj.length == 4 && obj[0]=='f'){

        items.push({iType:2,data:obj.replace("f","").toString()});
      }else{
        
        items.push({iType:1,data:obj});
      }
      
    });
    
    var confManager = this.session.swGetConfManager();

    var conf = confManager.swGetConfByConfId(selectObj.groupInfo.id);

    if (conf == null) {
            
      $.Toast("","Unable to send message! An unexpected error occurred.", "error", toastTopCenterOption);
  }
  else{

    conf.swConfIMSend({
      msgitems:  items,
      callback: function (opt, resp) {

        if (resp.emms.code != jSW.RcCode.RC_CODE_S_OK) {

          $.Toast("","Unable to send message! An unexpected error has occurred.", "error", toastTopCenterOption);
        }else{

          var value = [];

          items.forEach(l=>{

            if(l.iType==2){
              
              l.data =   "assets/js/baseVideo/jswlib/res/emotion/f"+l.data+".gif";
            }

            value.push({
              type:l.iType,
              data:l.data
            })
          });
          
        
          var newMsg = {
            id:  session._userManager._currentUser._user.id,
            name: bastGetUserNamefromID(session._userManager._currentUser._user.id),
            traget: selectObj.groupInfo.id,
            tragetName: "",
            isRead:false,
            create: getDateNow(),
            value:  value
          };
          
          onAddNewMessageToSession(newMsg);

        }
      },
      tag:null});
  }
    
  }
  else{


    objs.forEach(obj=>{
      
      if(obj.length == 4 && obj[0]=='f'){

        items.push({id:selectObj.deviceId,
          iType:2,
          data:obj.replace("f",""),
          nruid:"NRU_"
        });
      }else{

        items.push({
          id:selectObj.deviceId,
          iType:1,
          data:obj,
          nruid:"NRU_"
        });
      }
    });

    session.swImSend({
      targetid: selectObj.deviceId,
      msgitems: items,
      callback: (options,response) => {
        if (response.emms.code != jSW.RcCode.RC_CODE_S_OK) {
          
          $.Toast("","Unable to send message! An unexpected error has occurred.", "error", toastTopCenterOption);
        }else{
          
          var value = [];

          items.forEach(l=>{

            if(l.iType==2){
              
              l.data =   "assets/js/baseVideo/jswlib/res/emotion/f"+l.data+".gif";
            }

            value.push({
              type:l.iType,
              data:l.data
            })
          });

          var newMsg = {
            id:  session._userManager._currentUser._user.id,
            name: bastGetUserNamefromID(session._userManager._currentUser._user.id),
            traget: selectObj.userId,
            tragetName: bastGetUserNamefromID(selectObj.userId),
            isRead:false,
            create: getDateNow(),
            value:  value
          };

          onAddNewMessageToSession(newMsg);

        }

      },
      tag: null
  })
  }

    $('.main.communication #frame #emojiText').html("");
    $('.main.communication #frame .iconButton').removeClass("red");
    $(".main.communication #frame .content .message-input .iconDialog").removeClass("hidden").addClass("hidden");
  }
};

function scoreMessageToBottom(){
  $(".main.communication #communicationChat .messages").animate({ scrollTop: $(".main.communication #communicationChat .msg-body").length * 1000  }, "fast");
}

function onCommunicationClickSummit() {

    newMessage();
}

function onScrollChange(obj){

  if($(".main.communication #communicationChat").html() != undefined){

    if($(obj).scrollTop() == 0){

      var hiddenObj = $(obj).find("li.hidden");

      if(hiddenObj.length >= 10){

        for(var i=hiddenObj.length-1;i>=hiddenObj.length-10;i--){

          $(hiddenObj[i]).removeClass("hidden");
        }
        
        $(obj).animate({ scrollTop: $(obj).scrollTop()+300 }, "fast");
      }else if(hiddenObj.length > 0){

        for(var i=0;i < hiddenObj.length;i++){

          $(hiddenObj[i]).removeClass("hidden");
          $(obj).animate({ scrollTop: $(obj).scrollTop() }, "fast");
        }
      }
    }
  }
}

///////////// Conference ///////////////////

function startConference(obj){
  if($(".main.communication #communicationChat").html() != undefined){

    if(obj.id != "CU_SW"){

      $(".main.communication #frame .content .message-input .message-voice .message-curren-talk #msg-profile").attr("src", baseconfig.imageURL+ '/profile/'+obj.name+'.png?rnd='+Math.random().toString(36).substring(7));
      $(".main.communication #frame .content .message-input .message-voice .message-curren-talk:first").removeClass("hidden");
    }

  }
}

function endConference(obj){

  if($(".main.communication #communicationChat").html() != undefined){

    if(obj.id != "CU_SW"){

      $(".main.communication #frame .content .message-input .message-voice .message-curren-talk:first").addClass("hidden");
    }
  }
}
////// Communication ////////////////
function onOpenUserMessagePanel(obj,id){

  activeMessagePanel(true);

  var user = communicationList.filter(q=>q.type == "user" && q.userId ==id)[0];

  user.activeChat = true;

  $(obj).closest("#frame").find(".content .contact-profile img").attr('src', user.imageURL + '?rand=' + Math.random());
  $(obj).closest("#frame").find(".content .contact-profile p:first").html(user.userName);
  $(obj).closest("#frame").find(".content .contact-profile .social-media .groupChat").addClass("hidden");
  $(obj).closest("#frame").find(".content .contact-profile .social-media .pvChat").removeClass("hidden");
  $(obj).closest("#frame").find(".contact-profile-toggle").removeClass("hidden").addClass("hidden");
  $(obj).addClass("active");
  getCommunicationMessage("user",id);
}


function onOpenGroupMessagePanel(obj,id){

  activeMessagePanel(true);

  var group = communicationList.filter(q=>q.type == "group" && q.groupInfo.id ==id)[0];

  group.activeChat = true;

  $(obj).closest("#frame").find(".content .contact-profile img").attr('src', group.imageURL + '?rand=' + Math.random());
  $(obj).closest("#frame").find(".content .contact-profile p:first").html(group.groupInfo.name + ' (<span onClick="onContactToggleClick(this)">' + group.groupUsers.length + '</span>)');
  $(obj).closest("#frame").find(".content .contact-profile .social-media .groupChat").removeClass("hidden");
  $(obj).closest("#frame").find(".content .contact-profile .social-media .pvChat").removeClass("hidden").addClass("hidden");
  $(obj).closest("#frame").find(".contact-profile-toggle").removeClass("hidden").addClass("hidden");

  var groupImgHtml = "";

  onClearMeeting();

  group.groupUsers.forEach(l=>{

    var imgUrl =  baseconfig.imageURL+ '/profile/'+l.name+'.png?rnd='+Math.random().toString(36).substring(4);
    groupImgHtml += '<img class="group-img" src="'+ imgUrl +'" title="'+bastGetUserNamefromID(l.name)+'" onerror="this.src=\'assets/images/no-photo.png\'">'

  });

  $(".main.communication #frame .contact-profile-toggle-img").html(groupImgHtml);
  $(obj).addClass("active");
  getCommunicationMessage("group",id); 
}

function activeMessagePanel(isActive){

  $(".main.communication #frame #contacts .contact").each(function(index,value){

    if($(value).hasClass("active")){

      $(value).removeClass("active");
    }
  });

  communicationList.forEach(l=>{
    
    l.activeChat=false;
    
    if(l.activeMic == true){

      let channel = this.session.swGetPuChanel(l.deviceId, 0);
      var rc = channel.swCloseIntercom({
        callback: function(options, response) {
          if (response.emms.code == 0) {
  
            l.activeMic = false;
            $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").removeClass("color-red").addClass("color-green");
            $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").prop('title', "Start Call");
          }else{
  
            $.Toast("","Unable to start the call! An unexpected error occurred.", "error", toastTopCenterOption);
          }
        }
      });
    }
  });

  var talkPanel =  $(".main.communication #frame .content .message-input .message-voice");
  
  $(".main.communication #frame .message-input .wrap button").removeClass("red");
  $(".main.communication #frame .content .message-input .iconDialog").removeClass("hidden").addClass("hidden");
  $('.main.communication #frame #emojiText').html("");
  $(".main.communication #frame .messages").html('');

  if(!talkPanel.hasClass("hidden")){

    talkPanel.addClass("hidden");
    talkPanel.removeClass("show");
    $(".main.communication #frame .messages").removeClass("talk");
    $(".main.communication #frame .messages").html('');
    $(".main.communication #frame .content .contact-profile .social-media .groupChat").removeClass("hidden");
    $(".main.communication #frame .content .contact-profile .social-media .pvChat").removeClass("hidden").addClass("hidden");
  }

  if(isActive){

    $(".main.communication #frame .content .contact-profile img").removeClass("hidden");
    $(".main.communication #frame .content .contact-profile .social-media").removeClass("inActive");
    $(".main.communication #frame .content .message-input .wrap #emojiText").removeClass("inActive");
    $(".main.communication #frame .content .message-input .wrap .btMessage").removeClass("inActive");
  }else{
    
    $(".main.communication #frame .content .contact-profile img").removeClass("hidden").addClass("hidden");
    $(".main.communication #frame .content .contact-profile .social-media").removeClass("inActive").addClass("inActive");
    $(".main.communication #frame .content .message-input .wrap #emojiText").removeClass("inActive").addClass("inActive");;
    $(".main.communication #frame .content .message-input .wrap .btMessage").removeClass("inActive").addClass("inActive");;
    $(".main.communication #frame .content .contact-profile p").html('');
  }
}


function openDeleteMeeting(){

  if(communicationList.filter(q=>q.activeChat && q.type == 'group').length > 0){

    document.getElementById('deleteMeetingGroupModal').style.display='block';
  }
  
}

function onDeleteMeeting(obj){

  var obj = communicationList.filter(q=>q.activeChat)[0];

  if(obj.type == 'group'){

    session.swGetConfManager().swDeleteConf({
      confid: obj.groupInfo.id,
      callback: (sender, event, json) => {
  
        if(event.emms.code!=0){
  
          $.Toast("","Unable to delete group! An unexpected error has occurred.", "error", toastTopCenterOption);
          }else{
  
            $.Toast("","Group deleted successfully!", "success", toastTopCenterOption);
            document.getElementById('deleteMeetingGroupModal').style.display='none';
            CreateActiveCommunicationList();
            activeMessagePanel(false);
        }
      }});
  }
}

function onClosePVChat(){

  activeMessagePanel(false);
}

function onContactProfileImgScrolltoRight(){

  if($(".main.communication #frame .contact-profile-toggle-img").width() >  $(".contact-profile-toggle-img").scrollLeft() +40)
    $(".main.communication #frame .contact-profile-toggle-img").scrollLeft($(".main.communication #frame .contact-profile-toggle-img").scrollLeft()+40);
  else
    $(".main.communication #frame .contact-profile-toggle-img").scrollLeft($(".main.communication #frame .contact-profile-toggle-img").width()-40);
}


function onContactProfileImgScrolltoLeft(){

  if($(".main.communication #frame .contact-profile-toggle-img").scrollLeft() > 40)
    $(".main.communication #frame .contact-profile-toggle-img").scrollLeft($(".main.communication #frame .contact-profile-toggle-img").scrollLeft()-40);
  else
    $(".main.communication #frame .contact-profile-toggle-img").scrollLeft(0);
}

function onContactToggleClick(obj){

  if($(obj).hasClass("color-red")){

    $(obj).removeClass("color-red");
    $(".main.communication #frame .contact-profile-toggle").removeClass("hidden").addClass("hidden");
  }else{

    $(obj).removeClass("color-red").addClass("color-red");
    $(".main.communication #frame .contact-profile-toggle").removeClass("hidden");
  }
}

function openInviteMeetingModel(){

  var obj = communicationList.filter(q=>q.activeChat)[0];

  if(obj.type == 'group'){

    var isExistId = [];

    obj.groupUsers.forEach(l=>{

      isExistId.push(l.name);
    });
  }

  var modalBodyHtml = "";

  var count = 0;
  session._arr_pu_online.forEach(l=>{
    
    var currentId = l._userid;

    if(!isExistId.includes(currentId)) {
      count++;
      modalBodyHtml += '<div class="modal-item align-items-center"><div class="custom-checkbox">';
      modalBodyHtml += '<input type="checkbox" class="custom-control-input custome-checkbox" id="customCheck'+currentId+'">';
      modalBodyHtml += '<label class="custom-control-label" for="customCheck'+currentId+'"></label></div>';

      var imgUrl =  baseconfig.imageURL+ '/profile/'+currentId+'.png?rnd='+Math.random().toString(36).substring(4);
      modalBodyHtml += '<img class="group-img" src="'+ imgUrl +'" title="'+l._username+'" onerror="this.src=\'assets/images/no-photo.png\'">'

      modalBodyHtml += '<span>'+l._username+'</span><span class="id hidden">'+currentId+'</span></div>';
    }
  });

  if(count%3>0){
    for(var i=0;i<(3-count%3);i++){
      modalBodyHtml += '<div class="modal-item align-items-center"></div>'
    }
  }

  if(modalBodyHtml == ""){

    $.Toast("","Unable to invite users! An unexpected error occurred.", "error", toastTopCenterOption);
  }else{
    $(".main.communication  .modal-body").html(modalBodyHtml);
    $(".main.communication #inviteOnlineGroupModal").show();
  }
}

function inviteMeeting(obj){

  var selectObj = $(obj).closest("#inviteOnlineGroupModal").find(".modal-body .modal-item .custome-checkbox:checkbox:checked");

  if(selectObj.length > 0){

    var item = [];
    
    $.each(selectObj, function (key, value) {

      item.push($(value).closest(".modal-item").find(".id").html());
    });
   
    session.swGetConfManager()._getOnlineUsers({

      callback:(options,response,users)=>{

        var selectUser = [];

        users.forEach(l=>{

          if(item.includes(l.userid)) {
            selectUser.push({"id":l.devid,"name":l.userid,"applierid":l.iapplierid});
          }
        });

        if(selectUser.length > 0){

          var group = communicationList.filter(q=>q.activeChat)[0];

          let confManager = this.session.swGetConfManager();

          let conf = confManager.swGetConfByConfId(group.groupInfo.id);
          if (conf == null) {
              
              $.Toast("","Unable to invite users! An unexpected error occurred.", "error", toastTopCenterOption);
          }else{
            isTimerWaiting(true,"Waiting for user(s) to accept invitation...",30);
            conf.swGetParticularList({
              callback: (options, response, particularList) => {
                if (particularList.length > 0) {
                  conf.swParticipatorAdd({
                    users: selectUser,
                    callback:  (sender, event, json) => {

                      isTimerWaiting(false);
                      
                        if(event.emms.code!=0){

                          $.Toast("","Unable to invite users! An unexpected error occurred.", "error", toastTopCenterOption);
                        }else{

                          $.Toast("","Invitation sent to user(s) successfully!", "success", toastTopCenterOption);
                          CreateActiveCommunicationList();
                          $(".main.communication .contact"+group.groupInfo.id).dblclick();;
                          $(obj).closest(".w3-modal").hide();
                        }
                    }
                });
                }
                }
            });
          }
        }
      }
   });
  }
}

function onStartCommunication(obj){

    var group = communicationList.filter(q=>q.activeChat)[0];
    var confManager = this.session.swGetConfManager();
    var conf = confManager.swGetConfByConfId(group.groupInfo.id);
    
    if($(obj).hasClass("color-green")){

      conf.swConfStop({
          callback:  (sender, event, json) => {
              if(event.emms.code!=0){

                $.Toast("","Unable to stop communication session! An unexpected error occurred.", "error", toastTopCenterOption);
              }else{

                group.isStartMeeting = false;
                $(obj).removeClass("color-green");
                $(obj).prop('title', "Start Communication");
                
                $.Toast("","Group communication stopped successfully!", "success", toastTopCenterOption);
       
                onOnpenMicrophone(false);
                audioStream.stop();
                audioStream.getTracks().forEach(function(track) {
                  track.stop();
                });
                audioStream = null;
                
              }
          }
      });
    }else{

      if(conf._conf_base_info.bIsStarted){
        conf.swConfStop({
          callback:  (sender, event, json) => {

            }
        });
      }

      conf.swConfStart({
          callback:  (sender, event, json) => {
            if(event.emms.code!=0){
                return
            }
            conf.swParticipatorReturn({
              callback:  (sender, response, json) => {
                if(event.emms.code!=0){

                  $.Toast("","Unable to start communication session! An unexpected error occurred.", "error", toastTopCenterOption);
                }else{
                  
                  group.isStartMeeting = true;
                  $(obj).removeClass("color-green").addClass("color-green");
                  $(obj).prop('title', "Stop Communication");
                  $.Toast("","Group communication started successfully!", "success", toastTopCenterOption);
                  onOnpenMicrophone(true);
                  navigator.mediaDevices.getUserMedia({ audio: true })
                  .then(function(stream) {
                    audioStream = stream;
                  })
                  .catch(function(err) {
                  });
                }
              }
            });
          }
      });

    }
}

function onClearMeeting(){

   communicationList.filter(q=>q.type == "group").forEach(l=>{

    if(l.isStartMeeting){
        
      $(".main.communication #frame .social-media .fa-satellite-dish").removeClass("color-green");
      $(".main.communication #frame .social-media .fa-satellite-dish").prop('title', "Start Communication");

      var confManager = this.session.swGetConfManager();
      var conf = confManager.swGetConfByConfId(l.groupInfo.id);
      conf.swConfStop({
        callback:  (sender, event, json) => {
            if(event.emms.code!=0){
              return
            }
        }
      });
      l.isStartMeeting = false;
      if(audioStream){
        audioStream.stop();
        audioStream.getTracks().forEach(function(track) {
          track.stop();
        });
        audioStream = null;
      }
    }
  });
}

function onAdminHangUpPuttoTalk(){

  if($(".main.communication #frame .social-media .fa-satellite-dish").hasClass("color-green")
    &&  $(".main.communication #frame .content .message-input .message-voice .message-curren-talk:first").hasClass("hidden")){

    $("#audioBeep")[0].play();
    var group = communicationList.filter(q=>q.activeChat)[0];
    var confManager = this.session.swGetConfManager();
    var conf = confManager.swGetConfByConfId(group.groupInfo.id);
    let rc = conf.swApplyForSpeak({
      callback:(sender, event, json) => {
        if(event.emms.code==0){

          if (comTimerInterval != null) {
            clearInterval(comTimerInterval);
          }
          comTimePassed = 0;

          comTimerInterval = setInterval(() => {
            comTimePassed = comTimePassed += 1;
            comTimeLeft = 30 - comTimePassed;
            
            if (comTimeLeft <= 0) {
                onHunupTimesUp();
            }
          }, 1000);
          }
        }
    });
  }
}

function onHunupTimesUp(){

  if(comTimerInterval){

    $.Toast("","Push-to-talk button press is limited to 30 seconds!", "error", toastTopCenterOption);
    clearInterval(comTimerInterval);
    onAdminHangDownPuttoTalk()
  }

}

function onAdminHangDownPuttoTalk(){

  if($(".main.communication #frame .social-media .fa-satellite-dish").hasClass("color-green")){

    var group = communicationList.filter(q=>q.activeChat)[0];
    var confManager = this.session.swGetConfManager();
    var conf = confManager.swGetConfByConfId(group.groupInfo.id);
  
    conf.swApplyForEndSpeak({
      callback: function (sender, event, json) {
        if(event.emms.code==0){

          clearInterval(comTimerInterval);
        }
        }
    });
  }
}

function onUserAudioCall(){

  var user = communicationList.filter(l=>l.activeChat==true)[0];

  let channel = this.session.swGetPuChanel(user.deviceId, 0);
  
  if(!user.activeMic){

    var result = channel.swOpenIntercom({
      isSendAudio: true,
      isRecvAudio: true,
      callback: (options, response) => {
  
        if (response.emms.code == jSW.RcCode.RC_CODE_S_OK) {
  
          user.activeMic = true;
          $.Toast("","Audio call started successfully!", "success", toastTopCenterOption);

          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").removeClass("color-green").addClass("color-red");
          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").prop('title', "Stop Call");
        
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function(stream) {
              audioStream = stream;
            })
            .catch(function(err) {
            });
        }else{
  
          user.activeMic = false;
          $.Toast("","Unable to start the call! An unexpected error occurred.", "error", toastTopCenterOption);
          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").removeClass("color-red").addClass("color-green");
          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").prop('title', "Start Call");
        }
  
      },
      tag: channel
    });
  }else{

    var rc = channel.swCloseIntercom({
      callback: function(options, response) {
        if (response.emms.code == 0) {

          user.activeMic = false;
          $.Toast("","Call ended successfully!", "success", toastTopCenterOption);
          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").removeClass("color-red").addClass("color-green");
          $(".main.communication #frame .content .social-media .fa-phone.pvChat:first").prop('title', "Start Call");

          if(audioStream){
            audioStream.stop();
            audioStream.getTracks().forEach(function(track) {
              track.stop();
            });
            audioStream = null;
          }
        }else{

          $.Toast("","Unable to start the call! An unexpected error occurred.", "error", toastTopCenterOption);
        }
      }
    });
  }
  

}