var deviceList = [];
var googleMap;
var isCallingGPS;
var infowindow;
var recorder;
var mapTimestamp = [];



function initMap() {

    deviceList = [];
    mapTimestamp = [];

    var mapProp= {
      center:new google.maps.LatLng( baseLatitude, baseLongitude),
      zoom: baseconfig.defaultlocation.zoom,
      streetViewControl: false,
      mapTypeControl: true
    };
    googleMap = new google.maps.Map(document.getElementById("googleMap"),mapProp);
}

function CreateActiveDevicesList(){

    if($(".main.map .devicelist").html() != undefined){
        var isExistId = [];

        if(session._arr_pu_online.length > 0){

            var txtHtml = "<nb-list>";
            

            session._arr_pu_online.forEach(l => {

                  var currentId = l._userid;

                  if(!isExistId.includes(currentId)) {
  
                    isExistId.push(currentId);
  
                  
                    if(deviceList.filter(q=>q.deviceId == l._id_pu).length == 0){
    
                      deviceList.push({
                        deviceId:l._id_pu,
                        userId: l._userid,
                        isGetGps:false,
                        userName: l._username,
                        isTrackingGps: false
                      });
    
                    }else{
                      var device =  deviceList.filter(q=>q.deviceId == l._id_pu)[0];
                      device.id = l._id_pu;
                      device.userId = l._userid;
                      device.userName = l._username;
                    }
                    
                    var device =  deviceList.filter(q=>q.deviceId == l._id_pu)[0];
                    
                    var pinImg = new Image();
                    var pinImageURL = baseconfig.imageURL+ '/profile/'+currentId+'-pin.png?rnd='+Math.random().toString(36).substring(7);
                    pinImg.src = pinImageURL;
                    device.pinImageURL = pinImageURL;
                    pinImg.onerror = function(error,id = currentId){
      
                      deviceList.filter(q=>q.userId == id)[0].pinImageURL = "assets/images/no-photo-pin.png";
                    }
    
                    pinImg.onload = function(){ };
    
                    var img = new Image();
                    var imageURL = baseconfig.imageURL+ '/profile/'+currentId+'.png?rnd='+Math.random().toString(36).substring(7);
                    img.src = imageURL;
                    device.imageURL = imageURL;
                    img.onerror = function(error,id = currentId){
        
                      deviceList.filter(q=>q.userId == id)[0].imageURL = "assets/images/no-photo.png";
                      deviceList.filter(q=>q.userId == id)[0].pinImageURL = "assets/images/no-photo-pin.png";
                    }
    
                    img.onload = function(){ };
    
                    txtHtml += "<nb-list-item class='contact'><div class='nb-list-row row d-flex align-items-center'><div class='col-4'>";
                    txtHtml += "<img src='" + imageURL+"' onerror=\"this.src='assets/images/no-photo.png'\" >";
                    txtHtml += "<button _ngcontent-wyh-c21='' hero='' nbbutton='' shape='round' size='tiny' status='success' _nghost-wyh-c5='' ng-reflect-size='tiny' ng-reflect-status='success' ng-reflect-shape='round' ng-reflect-hero='' class='appearance-hero size-tiny status-success shape-round transitions' aria-disabled='false' tabindex='0' hero>Online</button></div><div class='col-6'>";
                    txtHtml += "<div class='user-name ng-star-inserted'>"+ currentId +"</div>";
    
                    txtHtml +=  "<div class='user-title ng-star-inserted'>"+ deviceList.filter(q=>q.deviceId == l._id_pu)[0].userName +"</div>";
    
                    txtHtml +=  "</div><div class='col-2 contract-control noPadding divPin"+currentId+"' title='Show Location'><nb-icon icon='pin' pack='eva'></nb-icon>";
                    txtHtml +=  "<i class='pin pin"+currentId+"' data-eva='pin' onclick=\"pinGps('"+currentId+"')\"></i>";
                    txtHtml +=  "</div></div></nb-list-item>";
                  }
            });

            txtHtml +=  "</nb-list>";

            $(".main.map .devicelist").html(txtHtml);
            removeOfflineDevice(isExistId);
        }else{

            removeOfflineDevice(isExistId);
            $(".main.map .devicelist").html("<h6>No devices online..</h6>");
        }

        eva.replace();
    }
}

// ==================================================== GPS ======================================

function pinGps(id){

  var device = deviceList.filter(q=>q.userId == id)[0];

  device.latitude = null;

  device.longitude = null;

  device.gps = session.swGetPuChanel(device.deviceId, 65536);

  if (device.gps == null) {

    $.Toast("","Cannot get device location!", "error", toastTopCenterOption);

    return ;
  }

  if (!device.isGetGps) {
    isWaiting(true);

    isCallingGPS = true;

    device.gps.swOpen({
        repeat: -1,
        interval: 100,
        callback: function(options,response,data = device){
          
            if(data){
              
              data.latitude = response.gps.lat / 10000000;
              data.longitude = response.gps.long / 10000000;
              isCallingGPS = false;
              pinDeviceToMap(device.userId);
            }
          }
        });

        getDataLoop = 0;
        
        getPinLocation(id);

        deviceList.forEach(l=>{

          if(l.isTrackingGps){
            l.isTrackingGps = false;
            infowindow.close();
          }
          
        });
    } else {

      isWaiting(false);
      device.latitude = null;
      device.longitude = null;
      activePin(id,false);
      device.isGetGps = false;
      device.isTrackingGps = false;
      device.gps.swClose();
      device.gps._callback = null;
      if(device.marker){
        device.marker.setMap(null);
        device.marker = null;
      }
    }
}

function activePin(id,isActive){

  if(isActive){

    $(".pin"+id).css("fill","lightgreen");
    $(".divPin"+id).prop('title', 'Hide Location');
  }else{

    $(".pin"+id).css("fill","lightgray");
    $(".divPin"+id).prop('title', 'Show Location');
  }
}

function getPinLocation(id){

  if(!isCallingGPS){

    var device = deviceList.filter(q=>q.userId == id)[0];

    if(!device.latitude || !device.longitude){

      $.Toast("","Cannot get device location!", "error", toastTopCenterOption);
    }else{

      device.isGetGps = true;

      activePin(id,true);

      SetPosition(id);
    }
    isWaiting(false);

  }else{
    if(getDataLoop<5){

      getDataLoop++;

      setTimeout(() => {

        getPinLocation(id);
      }, 1000);
    }else{

      $.Toast("","Cannot get device location!", "error", toastTopCenterOption);

      isWaiting(false);
    }

  }
}

function SetPosition(id,zoom = 0){

  var device = deviceList.filter(q=>q.userId == id)[0];
 
  if(device.marker){

    googleMap.setCenter(device.marker.getPosition());

    if(zoom != 0){ //0 is current zoom level

      googleMap.setZoom(zoom);
    }
  }else{
    setTimeout(() => {

      SetPosition(id,zoom);
    }, 1000);
  }
}

function pinDeviceToMap(id){

  if(mapTimestamp.filter(q=>q.userId ==id).length == 0){

    mapTimestamp.push({
      userId:id,
      old_timestamp:null
    });
  }

  var new_timestamp = new Date().valueOf();
  
  var timeStamp = mapTimestamp.filter(q=>q.userId == id)[0];

  if(timeStamp.old_timestamp == null || timeStamp.old_timestamp+1000 < new_timestamp){

    timeStamp.old_timestamp = new Date().valueOf();

    if($(".main.map .devicelist").html() != undefined && deviceList.length > 0){

      deviceList.forEach(l=>{

          if(l.latitude == null|| l.longitude == null || l.isGetGps == false) {

            if(l.marker){
              l.marker.setMap(null);
              l.marker = null;
            }
          }
          else{

            if(l.marker){

              l.marker.setPosition(new google.maps.LatLng( l.latitude, l.longitude ));

              if(l.isTrackingGps == true){

                deviceList.forEach(function(loop,index,object){
                  
                  if(loop.userId != l.userId){

                    if(loop.marker){

                      activePin(loop.userId,false);
                      if(loop.marker) loop.marker.setMap(null);
                    }
                    
                  }
                });

                SetPosition(l.userId,19);
              }

            }else{
              var icon = {
                url:  l.pinImageURL, // url
                scaledSize: new google.maps.Size(64, 64), // scaled size
                anchor: new google.maps.Point(32, 32) // anchor
            };

            l.marker = new google.maps.Marker({
                map:googleMap, 
                position: {lat: l.latitude, lng: l.longitude},
                icon: icon,
                title: l.userName
            });

            infowindow = new google.maps.InfoWindow();

            l.marker.addListener('click', function() {

            
            var infoContent = '<div class="pininfo" ><div class="pininfoleft" >';
            infoContent+= '<p class="pininfoname" ><b>'+ l.userName+'</b></p>'
            infoContent+= '<p class="pininfoid">'+ l.userId +'</p>';
            infoContent+= '<p class="pininfolat">Latitude: '+ l.latitude +'</p>';
            infoContent+= '<p class="pininfolng">Longitude: '+ l.longitude  +'</p></div>';
            infoContent+= '<div class="pininforight">';
            infoContent+= '<i class="fas fa-walking mapicon pininfogps';
            if(l.isTrackingGps) infoContent+= ' red" title="Stop Video"'; // check gps tracking
            else infoContent+= '" title="Start Tracking"';
            infoContent+= ' id="pininfogps'+ l.userId+'" onclick="fllowGPSPin(\''+l.userId +'\')"></i>';
            infoContent+= '<i title="Start Video" class="fas fa-video mapicon pininfolive" id="pininfolive'+ l.userId+'" onclick="videoLivePin(\''+l.userId +'\')"></i></div></div>';

            infowindow.setContent(infoContent);

              infowindow.open(googleMap, l.marker);
     
              });

            }
          }
      });
    }
  }
}

function removeOfflineDevice(isExistId){

  deviceList.forEach(function(l,index,object){

    if(l.isGetGps == true) activePin(l.userId,true);

    if(!isExistId.includes(l.userId)) {

      if(l.marker) l.marker.setMap(null);
      if(l.video){

        l.video.swClose();
        l.video = null;
	      document.getElementById('pinLiveVideoModal').style.display='none';
      }

      if(l.isRecord != null){
        
        recorder.stopRecording(function () {

          isWaiting(true);
          var blob =  recorder.getBlob();
          downloadBlobVideo(URL.createObjectURL(blob), l.userId + "-" + getDateExtendtion() + ".mp4");
          isWaiting(false);
          $(".pinLiveViewControlRight .fa-dot-circle").removeClass("red");
          clearInterval(l.RecordInterval);
          l.isRecord = null;
        });
        
      }

      if(l.videoTimeout != null){

        clearInterval(l.videoTimeout);
        l.videoTimeout = null;
      }

      if(l.videoTimer){

        $('#pinLiveVideoModal').find('.videotimer:first').removeClass("hidden").addClass("hidden");
          
        clearInterval(l.videoTimer);
        l.videoTimer = null;
        l.videoTimeCount = 0;
      }

      object.splice(index, 1);
    }

  });
}
// =========================== GPS Tracking =========================================

function closeGPSTracking(){

  deviceList.forEach(device=>{

    if(device.latitude != null && device.longitude != null){

      device.gps.swClose();
    } 
  });
}

function fllowGPSPin(id){

  var device = deviceList.filter(q=>q.userId == id)[0];

  if(device.isTrackingGps){

    device.isTrackingGps = false;
    $("#pininfogps"+id).removeClass("red");
    $("#pininfogps"+id).prop('title', 'Start Tracking');
  }else{

    device.isTrackingGps = true;
    $("#pininfogps"+id).addClass("red");
    $("#pininfogps"+id).prop('title', 'Stop Tracking');
  }
  
}

// =========================================== Video ======================================
function videoLivePin(Id){

  var device = deviceList.filter(q=>q.userId == Id)[0];

  if(device.video){

    device.video.swClose();
    device.video = null;
  }

  device.video = session.swGetPuChanel(device.deviceId, 0);
  isWaiting(true);
  var res = device.video.swOpenEx({
    div: 'pinLiveVideo',
    prototype: 'httpflv', //rtmp > hls
    media:10, // httpflv  10 = open audio , 2 = no audio
    callback: function (options, response) {

          isWaiting(false);
          $("#pinLiveVideo video").prop("volume", 0);

          document.querySelector("#pinLiveVideo video").disablePictureInPicture = true;
          $("#pointVolume").val(0);
          $(".pinLiveViewControlLeft i").removeClass( "fas fa-volume-off" ).addClass("fas fa-volume-mute");

          document.querySelector("#pinLiveVideo video").onvolumechange = function() {

            $("#pointVolume").val($("#pinLiveVideo video").get(0).volume * 100);

            if($("#pinLiveVideo video").prop('muted')){

              $("#pinLiveVideo video").prop("volume", 0);
              $(".divVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
              $("#pinLiveVideo video").prop('muted', false)
            }

            if($("#pointVolume").val() == 0 ){

              $(".divVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
            }else{
              
              $(".divVolume i").removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
            }

            device.videoTimeout = window.setInterval(function() {

                closeVideoLivePin();
            },baseconfig.videoSessionTimeout * 60 * 1000);

          };
        }
    });

  if (res != jSW.RcCode.RC_CODE_S_OK) {
    $.Toast("","Unable to load live video feed! An unexpected error has occurred.", "error", toastTopCenterOption);
  }else{
    document.getElementById('pinLiveVideoModal').style.display='block'
    $(".pinLiveViewBarID").html("Camera # " + device.userId);
  }
}

function closeVideoLivePin(){

  deviceList.forEach(l=>{

    
    if(l.isRecord != null){
        
      recorder.stopRecording(function() {

        isWaiting(true);
        var blob = recorder.getBlob();
        downloadBlobVideo(URL.createObjectURL(blob),l.userId + "-"+getDateExtendtion()+".mp4");
        isWaiting(false);
      });

      $(".pinLiveViewControlRight .fa-dot-circle").removeClass("red");
      clearInterval(l.RecordInterval);
    }

    if(l.videoTimer != null){

      $('#pinLiveVideoModal').find('.videotimer:first').removeClass("hidden").addClass("hidden");
          
      clearInterval(l.videoTimer);
      l.videoTimer = null;
      l.videoTimeCount = 0;
    }

    
    if(l.videoTimeout != null){

      clearInterval(l.videoTimeout);
      l.videoTimeout = null;
    }


    if(l.video){

      l.video.swClose();
      l.video = null;
    }
  });

  document.getElementById('pinLiveVideoModal').style.display='none';
}

function changeAudioVolumn(obj){

  if(obj.value == 0){

    $(obj).closest('#pinLiveVideoModal').find('video:first').prop("volume", 0);
    $(obj).closest('div').find('i:first').removeClass( "fas fa-volume-off" ).addClass("fas fa-volume-mute");
  }else{

    $(obj).closest('#pinLiveVideoModal').find('video:first').prop("volume", obj.value / 100);
    $(obj).closest('div').find('i:first').removeClass( "fas fa-volume-mute" ).addClass("fas fa-volume-off");
  }
}

function muteAudio(obj){

  var valueControl = $(obj).closest('#pinLiveVideoModal').find('#pointVolume:first');
  var videoControl = $(obj).closest('#pinLiveVideoModal').find('video:first');

  if(valueControl.val() == 0 ){

    $(obj).removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
    valueControl.val(100);
    videoControl.prop("volume", 1);
  }else{

    $(obj).removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
    valueControl.val(0);
    videoControl.prop("volume", 0);
  }

}

function openGPSLiveViewFullScreen(obj){

  document.getElementById($(obj).closest('#pinLiveVideoModal').find('video:first').attr('id')).requestFullscreen();
}

function TakeSnapshot(obj){

  isWaiting(true,"Taking snapshot...");
  var videoControl = $(obj).closest('#pinLiveVideoModal').find('video:first')[0];
  var canvas = document.createElement('canvas');
  canvas.height = videoControl.videoHeight;
  canvas.width = videoControl.videoWidth;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(videoControl, 0, 0, canvas.width, canvas.height);
  downloadImage(canvas.toDataURL('image/jpeg'),"SNAPSHOT-"+getDateExtendtion()+".jpg");

  setTimeout(() => {
    isWaiting(false);
  }, 500);
}

function RecordVideo(obj,highRes = false){

  deviceList.forEach(l=>{

    if(l.video){

      if(l.isRecord){

        l.isRecord = null;
        $(obj).removeClass("red");
        recorder.stopRecording(function() {

          isWaiting(true);
          var blob = recorder.getBlob();
          downloadBlobVideo(URL.createObjectURL(blob),l.userId + "-"+getDateExtendtion()+".mp4");
          isWaiting(false);
          clearInterval(l.RecordInterval);
        });

        if(l.videoTimer){

          $(obj).closest('#pinLiveVideoModal').find('.videotimer:first').removeClass("hidden").addClass("hidden");
          
          clearInterval(l.videoTimer);
          l.videoTimer = null;
          l.videoTimeCount = 0;
        }

      }else{
        
        var video = $(obj).closest('#pinLiveVideoModal').find('video:first')[0];

        if(video.videoHeight > 720 && !highRes){

          rechordObj = obj;
          openMapRecordHigestRes();
        }else{

          l.isRecord = true;
          $(obj).addClass("red");
          var canvas = document.getElementById("pinLiveVideoCanvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          var ctx = canvas.getContext("2d");
  
          l.RecordInterval = window.setInterval(function() {
            ctx.drawImage(video,5,5,video.videoWidth,video.videoHeight);
          },5);
  
          setTimeout(() => {
         
            recorder = RecordRTC(document.getElementById("pinLiveVideoCanvas"), {
              type: 'canvas',
              showMousePointer: true
            });
            recorder.startRecording();
          }, 100);
  
          $("#runTimer").html("00:00:00");
  
          $(obj).closest('#pinLiveVideoModal').find('.videotimer:first').removeClass("hidden");
    
          l.videoTimeCount = 0;
          
          l.videoTimer =  window.setInterval(function () {
            
            
            l.videoTimeCount +=1;
    
            $("#runTimer").html(l.videoTimeCount.toString().toHHMMSS());
          }, 1000);
  
          if(l.videoTimeout != null){
  
            clearInterval(l.videoTimeout);
            
            setTimeout(() => {
  
              l.videoTimeout = window.setInterval(function() {
  
                closeVideoLivePin();
              },baseconfig.videoSessionTimeout * 60 * 1000);
            }, 1000);
          }
        }
      }
    }
  });
}


function openMapRecordHigestRes(){

  document.getElementById('modalWarningHigestRes').style.display='block';
}

function closeMapRecordHigestRes(){

  document.getElementById('modalWarningHigestRes').style.display='none';
}

function recordMapWithHigestRes(){
  
  RecordVideo(rechordObj,true);
  document.getElementById('modalWarningHigestRes').style.display='none';
}