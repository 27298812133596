var activeSOS;
var recorder;
var sosGoogleMap;

function checkActiveSOS(){

  if(activeSOS){

    sosInfo = session._arr_pu_online.filter(q => q._id_pu == activeSOS.info._id_pu)[0];

    if(sosInfo == null){

      closeVideoSOS();
    }
  }

}

function videoSOS(puid){
  if($(".main.liveview").html() == undefined){
    LoadVideoSOS(puid);
  }else{
    var urlvars = getUrlVars();
    if(urlvars.mode == "EMERGENCYALERT" && urlvars.Id != null){

      if(liveList.filter(q=>q.userId == puid &&  q.video != null).length == 0){
        var qty = liveList.filter(q => q.video != null).length;
        console.log(qty);
        if(qty == 0) liveQty  = 1;
        else if(qty <=4) liveQty  = 4;
        else if(qty <=6)liveQty  = 6;
        else liveQty  = 1;
        setTimeout(() => {
          if(qty <=6) {
            playLiveVideo(puid);
            setTimeout(() => {
              onLiveViewShowMapRefresh();
            }, 500);
          }
          else LoadVideoSOS(puid);
        }, 500);
      }
    }else{
      LoadVideoSOS(puid);
    }


  }
}

function LoadVideoSOS(puid){
  parent.location.href = "/#/pages/dashboard";
  setTimeout(() => {
    window.location.href = "/#/pages/liveview?mode=EMERGENCYALERT&Id="+puid;
    setTimeout(() => {
      $('.view-button.single.shape-rectangle')[0].click();
    },500);
  }, 10);
}


function videoSOSold(puid){

    stopCurrentFeature();

    if(activeSOS){

      closeVideoSOS();
    }
    
    setTimeout(() => {
        

        if(activeSOS && activeSOS.video){
  
            activeSOS.video.swClose();
          }
      
          var sosInfo = session._arr_pu_online.filter(q => q._id_pu == puid)[0];
      
          activeSOS = {
              info:sosInfo,
              video:session.swGetPuChanel(sosInfo._id_pu, 0),
              isRecord:null,
              RecordInterval: null
          };
          
          isWaiting(true);
      
          var res = activeSOS.video.swOpenEx({
            div: 'sosVideo',
            prototype: 'httpflv', //rtmp > hls
            media:10, // httpflv  10 = open audio , 2 = no audio
            callback: function (options, response) {
        
                  isWaiting(false);
      
                  $("#sosVideo video").prop("volume", 0);
        
                  document.querySelector("#sosVideo video").disablePictureInPicture = true;
                  $("#sosPointVolume").val(0);
                  $(".sosControlLeft i").removeClass( "fas fa-volume-off" ).addClass("fas fa-volume-mute");
        
                  document.querySelector("#sosVideo video").onvolumechange = function() {
        
                    $("#sosPointVolume").val($("#sosVideo video").get(0).volume * 100);
        
                    if($("#sosVideo video").prop('muted')){
        
                      $("#sosVideo video").prop("volume", 0);
                      $(".sosDivVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
                      $("#sosVideo video").prop('muted', false)
                    }
        
                    if($("#sosPointVolume").val() == 0 ){
        
                      $(".sosDivVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
                    }else{
                      
                      $(".sosDivVolume i").removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
                    }
                  };
                }
            });
        
          if (res != jSW.RcCode.RC_CODE_S_OK) {
              $.Toast("","Unable to retrieve the device data! An unexpected error occurred.", "error", toastTopCenterOption);
          }else{
              document.getElementById('sosVideoModal').style.display='block'
              //$(".sosBar").html("Camera # " + device.userId);
              $("#sosVideoModal .row.bottom p.name").html(activeSOS.info._username);
              $("#sosVideoModal .row.bottom p.userId").html("("+activeSOS.info._userid+")");
              var mapProp= {
                  center:new google.maps.LatLng( baseLatitude, baseLongitude),
                  zoom: baseconfig.defaultlocation.zoom,
                  streetViewControl: false,
                  mapTypeControl: false
              };
      
              sosGoogleMap = new google.maps.Map(document.getElementById("sosGoogleMap"),mapProp);
      
              setTimeout(() => {
                  
                  var gps = session.swGetPuChanel(activeSOS.info._id_pu, 65536);
      
                  if (gps != null) {
            
                      gps.swOpen({
                          repeat: 2,
                          interval: 5000,
                          callback: function (options, response,currentGps= gps, id = activeSOS.info._userid) {

                            if(response.gps){

                              gps.swClose();
                              gps._callback = null;
      
                              if(response.gps){
                                  var latitude = response.gps.lat / 10000000;
                                  var longitude = response.gps.long / 10000000;

                                  var pinImg = new Image();
                                  var pinImageURL = baseconfig.imageURL + '/profile/' + id + '-pin.png?rnd=' + Math.random().toString(36).substring(7);
                                  pinImg.src = pinImageURL;
          
                                  pinImg.onerror = function (error, pin = pinImageURL) {
          
                                      var pin = "assets/images/no-photo-pin.png";
                                      var icon = {
                                      url: pin, // url
                                      scaledSize: new google.maps.Size(64, 64), // scaled size
                                      anchor: new google.maps.Point(32, 32) // anchor
                                      };
          
                                      var marker = new google.maps.Marker({
                                      map: sosGoogleMap,
                                      position: { lat: latitude, lng: longitude },
                                      icon: icon
                                      });
          
                                      sosGoogleMap.setCenter(marker.getPosition());
                                  }
          
                                  var icon = {
                                      url: pinImageURL, // url
                                      scaledSize: new google.maps.Size(64, 64), // scaled size
                                      anchor: new google.maps.Point(32, 32) // anchor
                                  };
          
                                  var marker = new google.maps.Marker({
                                      map: sosGoogleMap,
                                      position: { lat: latitude, lng: longitude },
                                      icon: icon
                                  });
                                  sosGoogleMap.setCenter(marker.getPosition());
                              }else{
      
                                  $.Toast("","Unable to retrieve the device data! An unexpected error occurred.", "error", toastTopCenterOption);
                              }
                            }
                          }
                      });
                  }else{
      
                      $.Toast("","Unable to retrieve the device data! An unexpected error occurred.", "error", toastTopCenterOption);
                  }
              }, 3000);
          }
    }, 1500);
}

function stopCurrentFeature(){

    if(liveList){

        liveList.forEach(l=>{

            if (l.video) {
        
              playLiveVideo(l.userId);
            }
        });
    }

    if(deviceList){

        deviceList.forEach(device=>{

            if(device.isGetGps){
                device.latitude = null;
                device.longitude = null;
                activePin(id,false);
                device.isGetGps = false;
                device.isTrackingGps = false;
                device.gps.swClose();
                device.gps._callback = null;
                if(device.marker){
                    device.marker.setMap(null);
                    device.marker = null;
                }
            }
        });
    }
    
}
  
function closeVideoSOS(){

    if(activeSOS.isRecord != null){
            
        recorder.stopRecording(function() {
    
          isWaiting(true);
          var blob = recorder.getBlob();
          downloadBlobVideo(URL.createObjectURL(blob),activeSOS.info._userid + "-"+getDateExtendtion()+".mp4");
          isWaiting(false);
          clearInterval(activeSOS.RecordInterval);
        });

        $(".sosControlRight .fa-dot-circle").removeClass("red");
        clearInterval(activeSOS.RecordInterval);
        activeSOS.isRecord = null;
    }

    if(activeSOS.videoTimer != null){

      $('#sosVideoModal').find('.sosVideotimer:first').removeClass("hidden").addClass("hidden");
      
      clearInterval(activeSOS.videoTimer);
      activeSOS.videoTimer = null;
      activeSOS.videoTimeCount = 0;
    }

    if(activeSOS.video){

        activeSOS.video.swClose();
        activeSOS.video = null;
    }

    document.getElementById('sosVideoModal').style.display='none';
}


function sosMuteAudio(obj){

    var valueControl = $(obj).closest('#sosVideoModal').find('#pointVolume:first');
    var videoControl = $(obj).closest('#sosVideoModal').find('video:first');

    if(valueControl.val() == 0 ){

        $(obj).removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
        valueControl.val(100);
        videoControl.prop("volume", 1);
    }else{

        $(obj).removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
        valueControl.val(0);
        videoControl.prop("volume", 0);
    }
}

function sosChangeAudioVolumn(obj){

    if(obj.value == 0){
  
      $(obj).closest('#sosVideoModal').find('video:first').prop("volume", 0);
      $(obj).closest('div').find('i:first').removeClass( "fas fa-volume-off" ).addClass("fas fa-volume-mute");
    }else{
  
      $(obj).closest('#sosVideoModal').find('video:first').prop("volume", obj.value / 100);
      $(obj).closest('div').find('i:first').removeClass( "fas fa-volume-mute" ).addClass("fas fa-volume-off");
    }
}


function sosTakeSnapshot(obj){

    isWaiting(true,"Taking snapshot...");
    var videoControl = $(obj).closest('#sosVideoModal').find('video:first')[0];
    var canvas = document.createElement('canvas');
    canvas.height = videoControl.videoHeight;
    canvas.width = videoControl.videoWidth;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(videoControl, 0, 0, canvas.width, canvas.height);
    downloadImage(canvas.toDataURL('image/jpeg'),"SNAPSHOT-"+getDateExtendtion()+".jpg");
  
    setTimeout(() => {
      isWaiting(false);
    }, 500);
}


function sosRecordVideo(obj,highRes = false){

      if(activeSOS && activeSOS.video){
  
        if(activeSOS.isRecord){
  
          activeSOS.isRecord = null;
          $(obj).removeClass("red");
          recorder.stopRecording(function() {
  
            isWaiting(true);
            var blob = recorder.getBlob();
            downloadBlobVideo(URL.createObjectURL(blob),activeSOS.info._userid + "-"+getDateExtendtion()+".mp4");
            isWaiting(false);
            clearInterval(activeSOS.RecordInterval);
          });

          if(activeSOS.videoTimer){

            $(obj).closest('#sosVideoModal').find('.sosVideotimer:first').removeClass("hidden").addClass("hidden");
            
            clearInterval(activeSOS.videoTimer);
            activeSOS.videoTimer = null;
            activeSOS.videoTimeCount = 0;
          }

        }else{
  
          var video =  $(obj).closest('#sosVideoModal').find('video:first')[0];

          if(video.videoHeight > 720 && !highRes){

            rechordObj = obj;
            openSOSRecordHigestRes();
          }else{

              activeSOS.isRecord = true;
              $(obj).addClass("red");
              var canvas = document.getElementById("sosVideoCanvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              var ctx = canvas.getContext("2d");
      
              activeSOS.RecordInterval = window.setInterval(function() {
                ctx.drawImage(video,5,5,video.videoWidth,video.videoHeight);
              },5);
      
              setTimeout(() => {
            
                recorder = RecordRTC(document.getElementById("sosVideoCanvas"), {
                  type: 'canvas',
                  showMousePointer: true
                });
                recorder.startRecording();
              }, 100);
              
              $("#sosRunTimer").html("00:00:00");
    
              $(obj).closest('#sosVideoModal').find('.sosVideotimer:first').removeClass("hidden");
    
              activeSOS.videoTimeCount = 0;
              
              activeSOS.videoTimer =  window.setInterval(function () {
                
                
                activeSOS.videoTimeCount +=1;
    
                $("#sosRunTimer").html(activeSOS.videoTimeCount.toString().toHHMMSS());
              }, 1000);
      
            }
          }
      }
}

function openGPSSOSFullScreen(obj){

    document.getElementById("sosVideoContainer").requestFullscreen();
}

function openSOSRecordHigestRes(){

  document.getElementById('modalSOSWarningHigestRes').style.display='block';
}

function closeSOSRecordHigestRes(){

  document.getElementById('modalSOSWarningHigestRes').style.display='none';
}

function recordSOSWithHigestRes(){
  
  sosRecordVideo(rechordObj,true);
  document.getElementById('modalSOSWarningHigestRes').style.display='none';
}