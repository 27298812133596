var liveList = [];
var liveQty;
var isShowGPS;
var liveviewOldTimeStamp = [];

function initLiveVideo() {
  liveList = [];
  liveQty = 4;
  isShowGPS = false;
  liveviewOldTimeStamp = [];

  var mapProp = {
    center: new google.maps.LatLng(baseLatitude, baseLongitude),
    zoom: baseconfig.defaultlocation.zoom,
    streetViewControl: false,
    mapTypeControl: true
  };
  googleMap = new google.maps.Map(document.getElementById("googleMap"), mapProp);

  $(".liveview.main .view-button.four").addClass("filled");
  $(".liveview.main .grid-view").html("");
  generateVideoStack();
  videoFeedLayout(4);
  createActiveLiveList();
  liveviewFooterControl();

  var urlvars = getUrlVars();

  if(urlvars.mode == "EMERGENCYALERT" && urlvars.Id != null){
    setTimeout(() => {
      playLiveVideo(urlvars.Id);
      setTimeout(() => onLiveViewShowMap(), 500);
    }, 500);
  }
}

function createActiveLiveList() {

  if ($(".main.liveview .onlineLivelist").html() != undefined) {
    var isExistId = [];

    if (session._arr_pu_online.length > 0) {

      var txtHtml = "<nb-list>";


      session._arr_pu_online.forEach(l => {

        var currentId = l._userid;

        if (!isExistId.includes(currentId)) {

          isExistId.push(currentId);

          if (liveList.filter(q => q.deviceId == l._id_pu).length == 0) {

            liveList.push({
              deviceId: l._id_pu,
              userId: currentId,
              userName: bastGetUserNamefromID(currentId),
            });

          } else {
            var liveUser = liveList.filter(q => q.deviceId == l._id_pu)[0];
            liveUser.id = l._id_pu;
            liveUser.userId = currentId;
            liveUser.userName = bastGetUserNamefromID(currentId);
          }

          var liveUser = liveList.filter(q => q.deviceId == l._id_pu)[0];

          var pinImg = new Image();
          var pinImageURL = baseconfig.imageURL + '/profile/' + currentId + '-pin.png?rnd=' + Math.random().toString(36).substring(7);
          pinImg.src = pinImageURL;
          liveUser.pinImageURL = pinImageURL;
          pinImg.onerror = function (error, id = currentId) {

            liveList.filter(q => q.userId == id)[0].pinImageURL = "assets/images/no-photo-pin.png";
          }

          pinImg.onload = function () { };

          var img = new Image();
          var imageURL = baseconfig.imageURL + '/profile/' + currentId + '.png?rnd=' + Math.random().toString(36).substring(7);
          img.src = imageURL;
          liveUser.imageURL = imageURL;
          img.onerror = function (error, id = currentId) {

            liveList.filter(q => q.userId == id)[0].imageURL = "assets/images/no-photo.png";
          }

          img.onload = function () { };

          txtHtml += "<nb-list-item class='contact'><div class='nb-list-row row d-flex align-items-center'><div class='col-4'>";
          txtHtml += "<div class='custom-checkbox'><input type='checkbox' class='custom-control-input custome-checkbox' id='customCheck" + currentId + "'><label class='custom-control-label' for='customCheck" + currentId + "'></label></div><img src='" + imageURL + "' onerror=\"this.src='assets/images/no-photo.png'\" >";
          txtHtml += "<button _ngcontent-wyh-c21='' hero='' nbbutton='' shape='round' size='tiny' status='success' _nghost-wyh-c5='' ng-reflect-size='tiny' ng-reflect-status='success' ng-reflect-shape='round' ng-reflect-hero='' class='appearance-hero size-tiny status-success shape-round transitions' aria-disabled='false' tabindex='0' hero>Online</button></div><div class='col-6'>";
          txtHtml += "<div class='user-name ng-star-inserted'>" + currentId + "</div>";

          txtHtml += "<div class='user-title ng-star-inserted'>" + liveList.filter(q => q.deviceId == l._id_pu)[0].userName + "</div>";

          txtHtml += "</div><div class='col-2 contract-control noPadding divlive" + currentId + "' title='Start Video'>";

          txtHtml += " <i class='fas fa-video pinlive  pinlive" + currentId + "'  onclick=\"playLiveVideo('" + currentId + "')\"></i>";

          txtHtml += "</div></div></nb-list-item>";
        }
      });

      txtHtml += "</nb-list>";
      $(".main.liveview .onlineLivelist").html(txtHtml);

      removeOfflineUser(isExistId);
    } else {

      removeOfflineUser(isExistId);
      $(".main.liveview .onlineLivelist").html("<h6>No users online..</h6>");
    }

    eva.replace();
  }
}

function closeLiveViewPage() {
  
  removeOfflineUser([]);
}

function removeOfflineUser(isExistId) {

  liveList.forEach(function (l, index, object) {

    setTimeout(() => {

      if (!isExistId.includes(l.userId)) {

        if (l.video) {
          var divCoverId = l.video.divCoverId;
          l.video.source.swClose();

          if (l.isRecord) {

            l.video.recorder.stopRecording(function () {

              isWaiting(true);
              var blob = l.video.recorder.getBlob();
              downloadBlobVideo(URL.createObjectURL(blob), l.userId + "-" + getDateExtendtion() + ".mp4");
              isWaiting(false);
              clearInterval(l.video.RecordInterval);
            });
            l.isRecord = null;
            clearInterval(l.video.RecordInterval);
          }
              
          if(l.videoTimeout != null){

            clearInterval(l.videoTimeout);
            l.videoTimeout = null;
          }

            
        if(l.videoTimer){

          clearInterval(l.videoTimer);
          l.videoTimeCount = 0;
        }
          
          setTimeout(() => {
            isWaiting(false);
            $("#" + divCoverId).remove();
            generateVideoStack();
            videoFeedLayout(liveQty);
          }, 1000);
        }
  
        l.latitude = null;
        l.longitude = null;
        l.isGetGps = false;
        if (l.gps) {
          l.gps.swClose();
          l.gps._callback = null;
          if (l.marker) {
            l.marker.setMap(null);
            l.marker = null;
          }
        }
  
        object.splice(index, 1);
        liveviewFooterControl();
      } else {
        if (l.video) {
            
            $(".pinlive.pinlive" + l.userId).removeClass("green").addClass("green");
            $(".pinlive.pinlive" + l.userId).prop('title', "Stop Video");
            $("#customCheck" + l.userId).prop('checked', false);
            $("#customCheck" + l.userId).attr("disabled", true);
        }
      }
    },100);
  });
}

function viewBottonClick(obj, qty) {

  $(".liveview.main .view-button").removeClass("filled");
  $(obj).addClass("filled");
  generateVideoStack();
  videoFeedLayout(qty);
  liveQty = qty;
}

function generateVideoStack() {

  var strHtml = "";
  var qtyActiveCamera = liveList.filter(q => q.video != null).length;

  for (i = 1; i <= 6; i++) {

    $(".liveview.main .grid-view .camera" + i).remove();

    if (i > qtyActiveCamera) {

      var divCameraID = randomString();
      strHtml += '<div class="camera camera' + i + '" id=' + divCameraID + '>';
      strHtml += '<div class="divVideo" id=' + randomString(9) + '></div>';
      strHtml += '<canvas id=' + randomString(10) + '></canvas>';
      strHtml += '<span class="camera-name">Camera #' + i + '</span>';

      strHtml += '<div class="videotimer d-flex align-items-center justify-content-end hidden"> <img src="./assets/images/icon-record.gif"><div id="runTimer'+divCameraID+'" class="runTimer">00:00:00</div></div>';

      strHtml += '<div class="liveViewControlLeft inActive"><div class="divVolume">';
      strHtml += '<i class="fas fa-volume-mute" onclick="muteAudioLiveView(this)" ></i>';
      strHtml += '<input class="pointVolume" name="pointVolume"  max="100" min="0" type="range" oninput="changeAudioVolumnLiveView(this)"></div></div>';

      strHtml += '<div class="video-control inActive">';
      strHtml += '<i class="fas fa-camera" title="Take Snapshot"  onclick="TakeSnapshotLiveView(this)"></i>';
      strHtml += '<i class="fas fa-dot-circle" title="Start Recording" onclick="RecordVideoLiveView(this)"></i>';
      strHtml += '<i class="fas fa-expand" title="Toggle Fullscreen Mode"  onclick="openGPSLiveViewFullScreenLiveView(this)"></i>';
      strHtml += '</div></div>';
    }
  }

  $(".liveview.main .grid-view").append(strHtml);
}

function videoFeedLayout(qty) {

  liveviewFooterControl();
  switch (qty) {
    case 1:
      $(".liveview.main .grid-view .camera").css('flex', '100%');
      $(".liveview.main .grid-view .camera").removeClass("cameraBorder");
      break;
    case 4:
      $(".liveview.main .grid-view .camera").css('flex', '50%');
      $(".liveview.main .grid-view .camera").removeClass("cameraBorder").addClass("cameraBorder");
      break;
    case 6:
      $(".liveview.main .grid-view .camera").css('flex', '33.33%');
      $(".liveview.main .grid-view .camera").removeClass("cameraBorder").addClass("cameraBorder");
      break;
    default:
      $(".liveview.main .grid-view .camera").css('flex', '100%');
      $(".liveview.main .grid-view .camera").removeClass("cameraBorder").addClass("cameraBorder");
  }

  var qtyActiveCamera = liveList.filter(q => q.video != null).length;

  var diff = qty - qtyActiveCamera

  for (i = 0; i < 6; i++) {

    if (diff >= 0 && i <= qty - 1)
      $($(".liveview.main .grid-view .camera")[i]).css('display', 'block');
    else if (i >= (diff * -1) && i <= qty - 1 + (diff * -1)) {
      $($(".liveview.main .grid-view .camera")[i]).css('display', 'block');
    }
    else
      $($(".liveview.main .grid-view .camera")[i]).css('display', 'none');


    if ((i + 1) > qtyActiveCamera) {

      $(".liveview.main .grid-view .camera" + (i + 1) + " .pointVolume").val(0);
    }
  }

  if (qty == 1) {

    $(".liveviewStart").removeClass("inActive").addClass("inActive");
    $(".liveviewStop").removeClass("inActive").addClass("inActive");
    $(".liveviewFullscreen").removeClass("inActive").addClass("inActive");

  } else {

    $(".liveviewStart").removeClass("inActive");
    $(".liveviewStop").removeClass("inActive");
    $(".liveviewFullscreen").removeClass("inActive");
  }

  liveviewFooterControl();
}

function playLiveVideo(id) {

  var liveVideo = liveList.filter(q => q.userId == id)[0];
  if (liveList.filter(q => q.video != null).length >= 6 && liveVideo.video == undefined) {

    $.Toast("", "Cannot load video from more than 6 devices!", "error", toastTopCenterOption);
  } else {

    if (liveVideo.video) {

      isWaiting(true, "Disconnecting...");
      liveVideo.video.source.swClose();
      if (liveVideo.isRecord) {

        liveVideo.isRecord = null;
        liveVideo.video.recorder.stopRecording(function () {

          isWaiting(true);
          var blob = liveVideo.video.recorder.getBlob();
          downloadBlobVideo(URL.createObjectURL(blob), liveVideo.userId + "-" + getDateExtendtion() + ".mp4");
          isWaiting(false);
          clearInterval(liveVideo.video.RecordInterval);
        });
      }

      if(liveVideo.videoTimer){

        $("#"+liveVideo.video.divCoverId).find('.videotimer:first').removeClass("hidden").addClass("hidden");
        
        clearInterval(liveVideo.videoTimer);
        liveVideo.videoTimer = null;
        liveVideo.videoTimeCount = 0;
      }

      if(liveVideo.videoTimeout != null){

        clearInterval(liveVideo.videoTimeout);
        liveVideo.videoTimeout = null;
      }

      $(".pinlive.pinlive" + id).removeClass("green");
      $(".pinlive.pinlive" + id).prop('title', "Start Video");
      $("#customCheck" + id).removeAttr("disabled");
      setTimeout(() => {
        isWaiting(false);
        if (liveVideo.video.divCoverId != undefined) $("#" + liveVideo.video.divCoverId).remove();
        liveVideo.video = null;
        generateVideoStack();
        videoFeedLayout(liveQty);
        liveviewFooterControl();
      }, 1000);
    } else {

      isWaiting(true, "Connecting...");
      $(".pinlive.pinlive" + id).removeClass("green").addClass("green");
      $(".pinlive.pinlive" + id).prop('title', "Stop Video");
      $("#customCheck" + id).prop('checked', false);
      $("#customCheck" + id).attr("disabled", true);
      var position = liveList.filter(q => q.video != null).length + 1;

      liveVideo.video = {};
      liveVideo.video.divCoverId = $(".liveview.main .grid-view .camera" + position).attr('id');
      liveVideo.video.divVideoId = $(".liveview.main .grid-view .camera" + position + " .divVideo").attr('id');
      $("#" + liveVideo.video.divCoverId).removeClass("camera" + position);
      liveVideo.video.source = session.swGetPuChanel(liveVideo.deviceId, 0);

      isWaiting(true);
      var res = liveVideo.video.source.swOpenEx({
        div: liveVideo.video.divVideoId,
        prototype: 'httpflv', //rtmp > hls
        media: 10, // httpflv  10 = open audio , 2 = no audio
        callback: function (options, response) {
          
          isWaiting(false);

          liveVideo.video.id = $("#" + liveVideo.video.divVideoId + " video").attr('id');
          liveVideo.video.canvasId = $("#" + liveVideo.video.divCoverId + " canvas").attr('id');

          $("#" + liveVideo.video.id).prop("volume", 0);
          $("#" + liveVideo.video.divCoverId + " .pointVolume").val(0);
          $("#" + liveVideo.video.divCoverId + " .pinLiveViewControlLeft i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");

          $("#" + liveVideo.video.divCoverId + " .liveViewControlLeft ").removeClass("inActive")
          $("#" + liveVideo.video.divCoverId + " .video-control").removeClass("inActive")

          document.getElementById(liveVideo.video.id).disablePictureInPicture = true;

          document.getElementById(liveVideo.video.id).onvolumechange = function () {
            $("#" + liveVideo.video.divCoverId + " .pointVolume").val($("#" + liveVideo.video.id).get(0).volume * 100);

            if ($("#" + liveVideo.video.id).prop('muted')) {

              $("#" + liveVideo.video.id).prop("volume", 0);
              $("#" + liveVideo.video.divCoverId + " .divVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
              $("#" + liveVideo.video.id).prop('muted', false)
            }

            if ($("#" + liveVideo.video.divCoverId + " .pointVolume").val() == 0) {

              $("#" + liveVideo.video.divCoverId + " .divVolume i").removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
            } else {

              $("#" + liveVideo.video.divCoverId + " .divVolume i").removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
            }
          };

          liveVideo.videoTimeout = window.setInterval(function() {

            playLiveVideo(id)
          },baseconfig.videoSessionTimeout * 60 * 1000);

        }
      });

      if (res != jSW.RcCode.RC_CODE_S_OK) {
        $.Toast("", "Unable to load live video feed! An unexpected error has occurred.", "error", toastTopCenterOption);
        $(".pinlive.pinlive" + id).removeClass("green");
        $(".pinlive.pinlive" + id).prop('title', "Start Video");
        $("#customCheck" + id).removeAttr("disabled");
        setTimeout(() => {
          isWaiting(false);
          if (liveVideo.video.divCoverId != undefined) $("#" + liveVideo.video.divCoverId).remove();
          liveVideo.video = null;
          generateVideoStack();
          videoFeedLayout(liveQty);
          liveviewFooterControl();
        }, 1000);
      } else {
        $("#" + liveVideo.video.divCoverId + " .camera-name").html("Camera # " + liveVideo.userId);
        videoFeedLayout(liveQty);
        liveviewFooterControl();
      }
    }
  }
}

function changeAudioVolumnLiveView(obj) {

  if (obj.value == 0) {

    $(obj).closest('.camera').find('video:first').prop("volume", 0);
    $(obj).closest('div').find('i:first').removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
  } else {

    $(obj).closest('.camera').find('video:first').prop("volume", obj.value / 100);
    $(obj).closest('div').find('i:first').removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
  }
}

function muteAudioLiveView(obj) {

  var valueControl = $(obj).closest('.camera').find('#pointVolume:first');
  var videoControl = $(obj).closest('.camera').find('video:first');

  if (valueControl.val() == 0) {

    $(obj).removeClass("fas fa-volume-mute").addClass("fas fa-volume-off");
    valueControl.val(100);
    videoControl.prop("volume", 1);
  } else {

    $(obj).removeClass("fas fa-volume-off").addClass("fas fa-volume-mute");
    valueControl.val(0);
    videoControl.prop("volume", 0);
  }

}

function openGPSLiveViewFullScreenLiveView(obj) {

  document.getElementById($(obj).closest('.camera').find('video:first').attr('id')).requestFullscreen();
}

function TakeSnapshotLiveView(obj) {

  isWaiting(true, "Taking snapshot...");
  var videoControl = $(obj).closest('.camera').find('video:first')[0];
  var canvas = document.createElement('canvas');
  canvas.height = videoControl.videoHeight;
  canvas.width = videoControl.videoWidth;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(videoControl, 0, 0, canvas.width, canvas.height);
  downloadImage(canvas.toDataURL('image/jpeg'), "SNAPSHOT-" + getDateExtendtion() + ".jpg");

  setTimeout(() => {
    isWaiting(false);
  }, 500);
}


function RecordVideoLiveView(obj,highRes = false) {

  var liveVideo = liveList.filter(q => q.video != null && q.video.divCoverId == $(obj).closest('.camera').attr('id'))[0];

  if (liveVideo.video) {
    if (liveVideo.isRecord) {

      liveVideo.isRecord = null;
      $(obj).removeClass("color-red");
      liveVideo.video.recorder.stopRecording(function () {

        isWaiting(true);
        var blob = liveVideo.video.recorder.getBlob();
        downloadBlobVideo(URL.createObjectURL(blob), liveVideo.userId + "-" + getDateExtendtion() + ".mp4");
        isWaiting(false);
        clearInterval(liveVideo.video.RecordInterval);
      });

      if(liveVideo.videoTimer){

        $(obj).closest('.camera').find('.videotimer:first').removeClass("hidden").addClass("hidden");
        
        clearInterval(liveVideo.videoTimer);

        liveVideo.videoTimer = null;
        liveVideo.videoTimeCount = 0;
      }
    } else {
      if(liveList.filter(q=>q.isRecord).length > 0){

        $.Toast("", "Multiple recording sessions are not allowed! Please stop the active live video recording session.", "warning", toastTopCenterOption);
      }else{

        var video = document.getElementById(liveVideo.video.id);

        if(video.videoHeight > 720 && !highRes){
  
          rechordObj = obj;
          openRecordHigestRes();
        }else{
  
          liveVideo.isRecord = true;
          $(obj).addClass("color-red");
          
          var canvas = document.getElementById(liveVideo.video.canvasId);
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          var ctx = canvas.getContext("2d");
    
          liveVideo.video.RecordInterval = window.setInterval(function () {
            ctx.drawImage(video, 5, 5, video.videoWidth, video.videoHeight);
          }, 5);
    
          setTimeout(() => {
    
            liveVideo.video.recorder = RecordRTC(document.getElementById(liveVideo.video.canvasId), {
              type: 'canvas',
              showMousePointer: false
            });
            liveVideo.video.recorder.startRecording();
          }, 1000);
    
          $("#runTimer"+liveVideo.video.divCoverId).html("00:00:00");
    
          $("#"+liveVideo.video.divCoverId).find('.videotimer:first').removeClass("hidden");
    
          liveVideo.video.videoTimeCount = 0;
          
          liveVideo.videoTimer =  window.setInterval(function () {
            
            
            liveVideo.video.videoTimeCount +=1;
    
            $("#runTimer"+liveVideo.video.divCoverId).html(liveVideo.video.videoTimeCount.toString().toHHMMSS());
          }, 1000);
    
          if(liveVideo.videoTimeout != null){
    
            clearInterval(liveVideo.videoTimeout);
            liveVideo.videoTimeout = null;
            
            liveVideo.videoTimeout = window.setInterval(function() {
    
              playLiveVideo(liveVideo.userId)
            },baseconfig.videoSessionTimeout * 60 * 1000);
          }
        }
      }
    }
  }
}

function onLiveViewPlayAll() {

  var selectVideoQty = $('.main.liveview .contact .custome-checkbox:checkbox:checked').length;

  if (selectVideoQty > 0) {

    var liveQty = liveList.filter(q => q.video != null).length;

    if (selectVideoQty + liveQty > 6) {

      $.Toast("", "Cannot load live video from more than 6 devices!", "error", toastTopCenterOption);
    } else {

      $.each($('.main.liveview .contact .custome-checkbox:checkbox:checked'), function (key, value) {

        $(value).closest('.nb-list-row').find('.pinlive').click();
      });
    }

  } else {

    $.Toast("", "No device selected! <br>Please select a device from online users list.", "warning", toastTopCenterOption);
  }

}

function onLiveViewStopAll() {

  liveList.forEach(l=>{

    if (l.video) {

      playLiveVideo(l.userId);
    }
  });

}

function liveviewFooterControl() {

  var liveQty = liveList.filter(q => q.video != null).length;

  if (liveQty > 0) {

    $(".liveviewStop").removeClass("objInActive");
    $(".liveviewFullscreen").removeClass("objInActive");
    $(".liveviewMap").removeClass("objInActive");
  } else {

    $(".liveviewStop").removeClass("objInActive").addClass("objInActive");
    $(".liveviewFullscreen").removeClass("objInActive").addClass("objInActive");
    $(".liveviewMap").removeClass("objInActive").addClass("objInActive");
  }

}

function onLiveViewAllFullScreen() {

  document.getElementById("liveGridView").requestFullscreen();
}


//=========================  Map =====================================

function onLiveViewShowMap() {

  isShowGPS = !isShowGPS;

  if (isShowGPS) {

    $("#liveviewUser").addClass("hidden");
    $("#liveviewGPS").removeClass("hidden");
    $(".liveviewStart").removeClass("objGPSInActive").addClass("objGPSInActive");
    $(".liveviewStop").removeClass("objGPSInActive").addClass("objGPSInActive");
    $("#liveviewMonitor").removeClass("col-lg-9").addClass("col-lg-6");
    $("#liveviewMonitor .liveviewMap a").addClass("red");

    var devices = liveList.filter(q => q.video != null);

    devices.forEach(device => {

      device.latitude = null;

      device.longitude = null;

      device.isGetGps = false;

      device.gps = session.swGetPuChanel(device.deviceId, 65536);

      if (device.gps == null) {

        $.Toast("", "Cannot get device location!", "error", toastTopCenterOption);

        return;
      }

      if (!device.isGetGps) {

        isWaiting(true);

        device.gps.swOpen({
          repeat: -1,
          interval: 5000,
          callback: function (options, response, data = device) {
            
            if(response.gps){

              if (liveviewOldTimeStamp.filter(q => q.userId == device.userId).length == 0) {

                liveviewOldTimeStamp.push({
                  userId: device.userId,
                  old_timestamp: null
                });
              }

              var new_timestamp = new Date().valueOf();
              var timeStamp = liveviewOldTimeStamp.filter(q => q.userId == device.userId)[0];

              if (timeStamp.old_timestamp == null || timeStamp.old_timestamp + 1000 < new_timestamp) {

                timeStamp.old_timestamp = new Date().valueOf();

                if (!data || !response || device.gps == null || !response.gps.lat || !response.gps.long) {

                  device.isGetGps = false;
                  device.gps.swClose();
                  device.gps._callback = null;
                  device.gps = null;
                } else {

                  device.isGetGps = true;
                  data.latitude = response.gps.lat / 10000000;
                  data.longitude = response.gps.long / 10000000;
                  liveviewPinDeviceToMap();

                  isWaiting(false);
                }
              }
            }
          }
        });

        setTimeout(() => {
          
          
          if(device.isGetGps== false){

            isWaiting(false);
            $.Toast("", "Unable to locate [" + device.userId + "] device!", "error", toastTopCenterOption);
          }

        }, 5000);
      } else {

        var device = liveList.filter(q => q.video != null);

        isWaiting(false);
        device.latitude = null;
        device.longitude = null;
        device.isGetGps = false;

        if(device.gps){

          device.gps.swClose();
          device.gps._callback = null;
        }

        if (device.isGetGps == false) {

          if (device.marker) {
            device.marker.setMap(null);
            device.marker = null;
          }
        }
      }

    });
  }
  else {

    $("#liveviewUser").removeClass("hidden");
    $("#liveviewGPS").addClass("hidden");
    $(".liveviewStart").removeClass("objGPSInActive");
    $(".liveviewStop").removeClass("objGPSInActive");
    $("#liveviewMonitor").removeClass("col-lg-6").addClass("col-lg-9");
    $("#liveviewMonitor .liveviewMap a").removeClass("red");

    var devices = liveList.filter(q => q.video != null);

    devices.forEach(device => {

      device.latitude = null;
      device.longitude = null;
      device.isGetGps = false;
      device.gps.swClose();
      device.gps._callback = null;
      device.gps = null;

      if (device.marker) {
        device.marker.setMap(null);
        device.marker = null;
      }
    });
  }
}

function onLiveViewShowMapRefresh(){

  if(isShowGPS){
      var devices = liveList.filter(q => q.video != null);

      devices.forEach(device => {
        if(device.isGetGps == null || device.isGetGps == false){
    
          device.latitude = null;
    
          device.longitude = null;
    
          device.isGetGps = false;
    
          device.gps = session.swGetPuChanel(device.deviceId, 65536);
    
          if (device.gps == null) {
    
            $.Toast("", "Cannot get device location!", "error", toastTopCenterOption);
    
            return;
          }
    
          if (!device.isGetGps) {
    
            isWaiting(true);
    
            device.gps.swOpen({
              repeat: -1,
              interval: 5000,
              callback: function (options, response, data = device) {
                
                if(response.gps){
    
                  if (liveviewOldTimeStamp.filter(q => q.userId == device.userId).length == 0) {
    
                    liveviewOldTimeStamp.push({
                      userId: device.userId,
                      old_timestamp: null
                    });
                  }
    
                  var new_timestamp = new Date().valueOf();
                  var timeStamp = liveviewOldTimeStamp.filter(q => q.userId == device.userId)[0];
    
                  if (timeStamp.old_timestamp == null || timeStamp.old_timestamp + 1000 < new_timestamp) {
    
                    timeStamp.old_timestamp = new Date().valueOf();
    
                    if (!data || !response || device.gps == null || !response.gps.lat || !response.gps.long) {
    
                      device.isGetGps = false;
                      device.gps.swClose();
                      device.gps._callback = null;
                      device.gps = null;
                    } else {
    
                      device.isGetGps = true;
                      data.latitude = response.gps.lat / 10000000;
                      data.longitude = response.gps.long / 10000000;
                      liveviewPinDeviceToMap();
    
                      isWaiting(false);
                    }
                  }
                }
              }
            });
    
            setTimeout(() => {
              
              
              if(device.isGetGps== false){
    
                isWaiting(false);
                $.Toast("", "Unable to locate [" + device.userId + "] device!", "error", toastTopCenterOption);
              }
    
            }, 5000);
          } else {
    
            var device = liveList.filter(q => q.video != null);
    
            isWaiting(false);
            device.latitude = null;
            device.longitude = null;
            device.isGetGps = false;
    
            if(device.gps){
    
              device.gps.swClose();
              device.gps._callback = null;
            }
    
            if (device.isGetGps == false) {
    
              if (device.marker) {
                device.marker.setMap(null);
                device.marker = null;
              }
            }
          }
        }
      });
  }else{
    onLiveViewShowMap();
  }
}


function liveviewPinDeviceToMap() {

  var devices = liveList.filter(q => q.video != null);

  if ($(".main.liveview").html() != undefined && devices.length > 0) {

    devices.forEach(l => {

      if (l.latitude == null || l.longitude == null || l.isGetGps == false) {

        if (l.marker) {
          l.marker.setMap(null);
          l.marker = null;
        }
      }
      else {

        if (l.marker && l.marker != null) {
          l.marker.setPosition(new google.maps.LatLng(l.latitude, l.longitude));
        } else {

          var icon = {
            url: l.pinImageURL, // url
            scaledSize: new google.maps.Size(64, 64), // scaled size
            anchor: new google.maps.Point(32, 32) // anchor
          };

          l.marker = new google.maps.Marker({
            map: googleMap,
            position: { lat: l.latitude, lng: l.longitude },
            icon: icon,
            title: l.userName
          });
          

          l.marker.addListener('click', function() {

          var infowindow = new google.maps.InfoWindow();
          var infoContent = '<div class="pininfo" ><div class="pininfoleft" >';
          infoContent+= '<p class="pininfoname" ><b>'+ l.userName+'</b></p>'
          infoContent+= '<p class="pininfoid">'+ l.userId +'</p>';
          infoContent+= '<p class="pininfolat">Latitude: '+ l.latitude +'</p>';
          infoContent+= '<p class="pininfolng">Longitude: '+ l.longitude  +'</p></div>';
          infoContent+= '<div class="pininforight">';
          infoContent+= '<div class=\'contract-control noPadding\' title="Open in Google Maps"><nb-icon icon="pin" pack="eva"></nb-icon><i class="pin " data-eva="pin"  onclick="openGoogleMap(\''+l.latitude+'\',\''+l.longitude+'\')"></i></div></div></div>';


          infowindow.setContent(infoContent);
          infowindow.open(googleMap, l.marker);
          
          setTimeout(() => {
            eva.replace();
          }, 100);
    
          });

          liveviewSetPosition(l.userId);

        }
      }
    });
  }
}

function openGoogleMap(lat,lng){
  window.open('https://www.google.com/maps/search/?api=1&query='+lat+','+lng, '_blank');
}

function liveviewSetPosition(id, zoom = 0) {

  var device = liveList.filter(q => q.userId == id)[0];

  if (device.marker && device.marker != null) {

    googleMap.setCenter(device.marker.getPosition());

    if (zoom != 0) { //0 is current zoom level

      googleMap.setZoom(zoom);
    }
  } else {
    setTimeout(() => {

      liveviewSetPosition(id, zoom);
    }, 1000);
  }
}

function onLiveSelectAll(isCheckAll){

  if(isCheckAll){
    $(".main.liveview .custome-checkbox").prop('checked', true);
  }else{
    $(".main.liveview .custome-checkbox").prop('checked', false);
  }

}

function openRecordHigestRes(){

  document.getElementById('modalWarningHigestRes').style.display='block';
}

function closeRecordHigestRes(){

  document.getElementById('modalWarningHigestRes').style.display='none';
}

function recordWithHigestRes(){
  
  RecordVideoLiveView(rechordObj,true);
  document.getElementById('modalWarningHigestRes').style.display='none';
}